import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField } from "@mui/material";
import { authenticate } from "../../utils/authenticate";
import AdminDetailsContext from "../../context/adminDetailsContext";
import ILocalStorageEnum from "../../enums/LocalStorageEnum";

const Login = () => {
  const { setAdminDetails } = useContext(AdminDetailsContext);
  const navigate = useNavigate();

  const [form, setform] = useState<ILoginForm>({
    name: {
      value: "",
      isValid: true,
      errorMessage: "",
    },
    password: {
      value: "",
      isValid: true,
      errorMessage: "",
    },
  });

  const loginHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    authenticate(form.name.value, form.password.value)
      .then((data) => {
        const loginData = data as unknown as {
          accessToken: { jwtToken: string; payload: { username: string } };
        };
        const loginDetails = {
          accessToken: loginData.accessToken.jwtToken,
          username: loginData.accessToken.payload.username,
        };

        setAdminDetails(loginDetails);
        localStorage.setItem(
          ILocalStorageEnum.THINKIFIC_USER_DETAILS,
          JSON.stringify(loginDetails),
        );
        navigate("/");
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
        /* eslint-disable no-console */
      });
  };

  useEffect(() => {
    if (window.self === window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (thinkificUserDetails) {
        navigate("/");
      }
    }
  }, [navigate]);

  const formChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const formObject = { ...form };

    const element = { ...formObject[name] };

    element.value = value;

    formObject[name] = { ...element };

    setform({ ...form, [name]: formObject[name] });
  };

  return (
    <Grid
      sx={{
        background: "#e4e5e6",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Grid
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={loginHandler}>
          <Grid sx={{ background: "white", padding: "40px 20px" }}>
            <TextField
              error
              type="text"
              variant="standard"
              value={form.name.value}
              onChange={formChangeHandler}
              name="name"
              fullWidth
              sx={{
                display: "block",
                padding: "10px",
                width: "300px",
              }}
              placeholder="Username"
            />
            <TextField
              error
              type="password"
              variant="standard"
              value={form.password.value}
              onChange={formChangeHandler}
              name="password"
              fullWidth
              sx={{ display: "block", width: "300px", padding: "10px" }}
              placeholder="Password"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ marginLeft: "10px" }}
            >
              Login
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
