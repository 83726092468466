import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableContainer,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import AlertContext from "../../context/alertContext";
import {
  getAllMasterData,
  createMasterData,
  editMasterData,
} from "../../APIs/masterDataApi";
import formatString from "../../utils/stringUtils";
import EditMasterDataStyles from "../../styles/editMasterData";

const EditMasterData: React.FC = () => {
  const { errorAlert } = useContext(AlertContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rowData, setRowData] = useState<any[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [masterData, setMasterData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [editRow, setEditRow] = useState<any>(null);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [newMasterDataValue, setNewMasterDataValue] = useState<string>("");
  const [selectedAutocompleteId, setSelectedAutocompleteId] = useState<
    string | undefined
  >(undefined);

  const handleNewMasterDataValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewMasterDataValue(event.target.value);
  };

  const handleCloseAddModal = () => {
    setNewMasterDataValue("");
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => setOpenEditModal(true);

  const handleCloseEditModal = () => {
    setEditRow(null);
    setOpenEditModal(false);
  };

  const handleOpenAddModal = () => setOpenAddModal(true);

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const data = await getAllMasterData(errorAlert);
        if (data) {
          setMasterData(data);
          setSelectedLabel(data[0]?.type || "");
          const filteredData = data.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => item.type === (data[0]?.type || ""),
          );
          setRowData(filteredData);
        }
      } catch (error) {
        errorAlert("Error fetching master data");
      }
    };

    fetchMasterData();
  }, [errorAlert]);

  const handleSaveAdd = async () => {
    try {
      if (!newMasterDataValue) {
        errorAlert("Please fill all fields!");
        return;
      }
      if (selectedLabel === "subType" && !selectedAutocompleteId) {
        errorAlert("Please select type!");
        return;
      }
      const requestBody: IMasterDataObject = {
        value: newMasterDataValue,
        type: selectedLabel,
      };
      if (selectedLabel === "subType" && selectedAutocompleteId) {
        requestBody.reference = selectedAutocompleteId;
      }
      const newData = await createMasterData(requestBody, errorAlert);
      if (newData) {
        setMasterData((prevData) => [...prevData, newData]);
        setRowData((prevRowData) => [...prevRowData, newData]);
        setNewMasterDataValue("");
        setOpenAddModal(false);
      }
    } catch (error) {
      errorAlert("Failed to add new data. Please try again.");
    }
  };

  const handleSaveEdit = async () => {
    try {
      if (selectedLabel === "subType" && !selectedAutocompleteId) {
        errorAlert("Please select type!");
        return;
      }
      if (editRow) {
        const { _id: id, value, type } = editRow;
        const requestBody: IMasterDataObject = { value, type };
        if (selectedLabel === "subType" && selectedAutocompleteId) {
          requestBody.reference = selectedAutocompleteId;
        }
        const updatedData = await editMasterData(id, requestBody, errorAlert);
        if (updatedData) {
          const updatedMasterData = await getAllMasterData(errorAlert);
          if (updatedMasterData) {
            setMasterData(updatedMasterData);
            const filteredData = updatedMasterData.filter(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (item: any) => item.type === selectedLabel,
            );
            setRowData(filteredData);
          }
        }
      }
      handleCloseEditModal();
    } catch (error) {
      errorAlert("Failed to save edited data. Please try again.");
    }
  };

  const handleListItemClick = (type: string) => {
    setSelectedLabel(type);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredData = masterData.filter((item: any) => item.type === type);
    setRowData(filteredData);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEditRow = (row: any) => {
    setEditRow(row);
    handleOpenEditModal();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={EditMasterDataStyles.gridContainer}>
          <Typography sx={EditMasterDataStyles.headerTypo}>
            {" "}
            Edit Master Data
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={EditMasterDataStyles.verticalBorder}>
          <List
            component="nav"
            aria-label="secondary mailbox folder"
            sx={EditMasterDataStyles.listContent}
          >
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              Array.from(new Set(masterData.map((item: any) => item.type))).map(
                (type: string) =>
                  type !== "gradeBands" && (
                    <ListItemButton
                      key={type}
                      onClick={() => handleListItemClick(type)}
                    >
                      <ListItemText primary={formatString(type)} />
                    </ListItemButton>
                  ),
              )
            }
          </List>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={EditMasterDataStyles.tableContent}>
            <Box sx={EditMasterDataStyles.addBtn}>
              <Typography sx={EditMasterDataStyles.tableLabel}>
                {formatString(selectedLabel)}
              </Typography>
              <Button variant="outlined" onClick={handleOpenAddModal}>
                Add
              </Button>
            </Box>
            <br />
            <Box>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={EditMasterDataStyles.tableRowFont}>
                        Name
                      </TableCell>
                      <TableCell
                        sx={EditMasterDataStyles.tableRowFont}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        sx={EditMasterDataStyles.tableRowFont}
                        align="center"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={EditMasterDataStyles.tableRowStyle}
                        >
                          {row.value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={EditMasterDataStyles.toggleRow}
                        >
                          <ToggleOnIcon sx={EditMasterDataStyles.toggleFont} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={EditMasterDataStyles.tableRowStyle}
                        >
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEditRow(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Add Modal */}
      <Dialog open={openAddModal} onClose={handleCloseAddModal}>
        <DialogTitle>
          <Typography sx={EditMasterDataStyles.modalTypo}>
            Add {formatString(selectedLabel)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedLabel === "subType" && (
            <Autocomplete
              options={masterData.filter((item) => item.type === "type")}
              getOptionLabel={(option) => option.value}
              onChange={(
                event: React.ChangeEvent<unknown>,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                newValue: any | null,
              ) => {
                setSelectedAutocompleteId(newValue?._id);
              }}
              renderInput={(params) => <TextField {...params} label="Type" />}
            />
          )}

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={newMasterDataValue}
            onChange={handleNewMasterDataValueChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal}>Cancel</Button>
          <Button onClick={handleSaveAdd} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Modal */}
      <Dialog open={openEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>
          <Typography sx={EditMasterDataStyles.modalTypo}>
            Edit {formatString(selectedLabel)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedLabel === "subType" && (
            <Autocomplete
              id="combo-box-demo"
              options={masterData.filter((item) => item.type === "type")}
              getOptionLabel={(option) => option.value}
              onChange={(
                event: React.ChangeEvent<unknown>,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                newValue: any | null,
              ) => {
                setSelectedAutocompleteId(newValue?._id);
              }}
              renderInput={(params) => <TextField {...params} label="Type" />}
            />
          )}

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={editRow ? editRow.value : ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEditRow({ ...editRow, value: e.target.value })
            }
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditMasterData;
