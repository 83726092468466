const BASE_URL = process.env.REACT_APP_BASE_URL;

const fetchActivityData = async (activityId: string): Promise<IActivity> => {
  try {
    const response = await fetch(`${BASE_URL}/activity/${activityId}`);

    if (!response.ok) {
      throw new Error("Failed to fetch activity data");
    }
    return await response.json();
  } catch (error) {
    /* eslint-disable no-console */
    console.error("Error fetching activity data:", error);
    /* eslint-disable no-console */
    throw error;
  }
};

const fetchUserActivityById = async (
  userId: string,
  id: string,
  thinkificUserInfo: IThinkificUser,
): Promise<IActivity> => {
  try {
    const response = await fetch(
      `${BASE_URL}/activity/user/${userId}/id/${id}`,
      {
        method: "post",
        body: JSON.stringify({ ...thinkificUserInfo }),
        headers: {
          "content-type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch activity data");
    }
    return await response.json();
  } catch (error) {
    /* eslint-disable no-console */
    console.error("Error fetching activity data:", error);
    /* eslint-disable no-console */
    throw error;
  }
};

const fetchUserActivityByDocId = async (
  userId: string,
  docId: string,
  thinkificUserInfo: IThinkificUser,
): Promise<IActivity> => {
  try {
    const response = await fetch(
      `${BASE_URL}/activity/user/${userId}/activityId/${docId}`,
      {
        method: "post",
        body: JSON.stringify({ ...thinkificUserInfo }),
        headers: {
          "content-type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch activity data");
    }
    return await response.json();
  } catch (error) {
    /* eslint-disable no-console */
    console.error("Error fetching activity data:", error);
    /* eslint-disable no-console */
    throw error;
  }
};

export { fetchActivityData, fetchUserActivityById, fetchUserActivityByDocId };
