import React, { useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

interface SingleFileUploaderProps {
  onFileUpload: (files: File[]) => void;
}

const SingleFileUploader: React.FC<SingleFileUploaderProps> = ({
  onFileUpload,
}) => {
  const [previews, setPreviews] = useState<{ file: File; preview: string }[]>(
    [],
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const newPreviews = selectedFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

      onFileUpload(selectedFiles);
    }
  };

  const handleRemoveFile = (file: File) => {
    setPreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((p) => p.file !== file);
      return updatedPreviews;
    });
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <label htmlFor="file">
            <input
              id="file"
              type="file"
              multiple
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              variant="outlined"
              component="span"
              style={{ fontFamily: "Montserrat" }}
              startIcon={<CloudUpload />}
            >
              Choose files
            </Button>
          </label>
        </Grid>
        {previews.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="body1" color="textPrimary">
              Selected files:
            </Typography>
            <Grid container spacing={2}>
              {previews.map(({ preview, file }) => (
                <Grid item xs={4} key={file.name + file.lastModified}>
                  <div>
                    <img
                      src={preview}
                      alt={`preview-${file.name}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                    <Button
                      variant="contained"
                      color="error"
                      style={{
                        top: 8,
                        right: 8,
                        padding: "4px",
                        minWidth: "auto",
                      }}
                      onClick={() => handleRemoveFile(file)}
                    >
                      X
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SingleFileUploader;
