import React from "react";
import { Tabs, Tab, Typography, Box, Paper } from "@mui/material";
import RichTextEditor from "./RichTextEditor";
import RichTextTabs from "../../../styles/richTextTabs";

interface TabComponentProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabData: string;
  handleTabDataChange: (newData: string) => void;
  isEditing: boolean; // Ensure this prop is used
}

const CustomTabPanel: React.FC<{
  children?: React.ReactNode;
  value: number;
  index: number;
}> = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      <Box sx={RichTextTabs.tabBoxContent}>
        <Typography variant="subtitle1">{children}</Typography>
      </Box>
    )}
  </div>
);

CustomTabPanel.defaultProps = {
  children: null,
};

const TabComponent: React.FC<TabComponentProps> = ({
  value,
  handleChange,
  tabData,
  handleTabDataChange,
  isEditing,
}) => {
  const tabLabels = [
    "Equipment Description",
    "Organization",
    "Instructions",
    "Teaching Suggestion",
    "Variations",
    "Differentiations",
    "Sel Reinforcement",
    "Inclusion Adaptation",
    "Virtual Adaptation",
  ];

  return (
    <Box sx={RichTextTabs.tabContent}>
      <Paper>
        <Box sx={RichTextTabs.boxDivider}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={RichTextTabs.tabsBackground}
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={label}
                label={label}
                value={index}
                sx={RichTextTabs.tabLabels}
              />
            ))}
          </Tabs>
        </Box>
        {tabLabels.map((label, index) => (
          <CustomTabPanel key={label} value={value} index={index}>
            {isEditing ? (
              <RichTextEditor
                value={tabData}
                tabData={tabData}
                handleTabDataChange={handleTabDataChange}
              />
            ) : (
              <RichTextEditor
                value={tabData}
                tabData={tabData}
                handleTabDataChange={handleTabDataChange}
              />
            )}
          </CustomTabPanel>
        ))}
      </Paper>
    </Box>
  );
};

export default TabComponent;
