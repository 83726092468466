/* eslint-disable react/no-danger */
import React, {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Container,
  Button,
  Collapse,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  InputBase,
  // Divider,
} from "@mui/material";
import "./view-single-activity.scss";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ForwardIcon from "@mui/icons-material/Forward";
// import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import copyUrlData from "./copy-url.json";
import LoaderContext from "../../../context/loaderContext";
import AlertContext from "../../../context/alertContext";
import {
  fetchActivityData,
  fetchUserActivityByDocId,
  fetchUserActivityById,
} from "../../../APIs/viewSingleActivityApi";
import IViewSingleEnum from "../../../enums/ViewSingleEnum";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";
import SearchContext from "../../../context/searchContext";
import ThinkificUserContext from "../../../context/thinkificUserContext";
import isEmpty from "../../../utils/ObjectIsEmpty";
import { saveActivityForUser } from "../../../APIs/activitiesApi";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldType = string | any[] | undefined;

function isFieldEmpty(field: FieldType): boolean {
  return !field || (Array.isArray(field) && field.length === 0);
}

interface IViewSingleActivity {
  activityId: string | null;
  setActivityId: Dispatch<SetStateAction<string | null>>;
}

function ViewSingleActivity({
  activityId,
  setActivityId,
}: IViewSingleActivity) {
  const [isOpen, setIsOpen] = useState(false);
  const { errorAlert, successAlert } = useContext<IAlertContext>(AlertContext);
  const { setIsLoader } = useContext<ILoader>(LoaderContext);
  const { thinkificUserInfo, setThinkificUserInfo } =
    useContext(ThinkificUserContext);
  const { searchFilterDetails } =
    useContext<ISearchParamsContext>(SearchContext);

  const location = useLocation();

  const [standardAlign, setstandardAlign] =
    React.useState<HTMLButtonElement | null>(null);
  const [selAlign, setSelAlign] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [activityData, setActivityData] = useState<IActivity | null>(null);
  const [language, setLanguage] = useState<IViewSingleEnum>(
    IViewSingleEnum.english,
  );
  const [expanded, setExpanded] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activityUrl, setActivityUrl] = useState<string>("");

  const id = activityId;
  const navigate = useNavigate();

  const standardOpen = Boolean(standardAlign);
  const standardAlignment = standardOpen ? "simple-popover" : undefined;
  const selOpen = Boolean(selAlign);
  const selAlignment = selOpen ? "simple-popover" : undefined;

  const onPrint = () => {
    const styles = document.createElement("style");
    styles.textContent = `
    .hide-on-print { display: none; }
    .show-on-print { display: block !important; }
  `;
    document.head.appendChild(styles);
    setExpanded(true);
    setIsOpen(true);
    window.print();
    styles.remove();
    setExpanded(false);
    setIsOpen(false);
  };

  const handleAccordionChange = () => {
    setExpanded(true);
  };

  const handleStandardClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setstandardAlign(event.currentTarget as HTMLButtonElement);
  };

  const handleSelClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setSelAlign(event.currentTarget as HTMLButtonElement);
  };

  const handleStandardAlignment = (
    event:
      | React.MouseEvent<HTMLSpanElement>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    if ("key" in event && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
    }
    handleStandardClick(event as React.MouseEvent<HTMLSpanElement>);
  };
  const handleSelAlignment = (
    event:
      | React.MouseEvent<HTMLSpanElement>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    if ("key" in event && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
    }
    handleSelClick(event as React.MouseEvent<HTMLSpanElement>);
  };

  const handleClose = () => {
    setstandardAlign(null);
    setSelAlign(null);
  };

  const handleAlignButtonClick = () => {
    window.open(
      "https://catch.org/physical-activity-standards-alignments",
      "_blank",
    );
  };
  const handleSelButtonClick = () => {
    window.open("https://catch.org/physical-activity-sel-alignments", "_blank");
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      try {
        if (!id) {
          setActivityData(null);
          return;
        }
        if (window.self === window.top) {
          const data = await fetchActivityData(id);
          setActivityData(data);
          document.documentElement.style.setProperty(
            "--card-bg-color",
            data?.type?.color || "#855fa7",
          );
        } else if (thinkificUserInfo.activityId) {
          const data = await fetchUserActivityByDocId(
            thinkificUserInfo?.id?.toString(),
            thinkificUserInfo.activityId,
            thinkificUserInfo,
          );
          setActivityData(data);
          document.documentElement.style.setProperty(
            "--card-bg-color",
            data?.type?.color || "#855fa7",
          );
        } else {
          const data = await fetchUserActivityById(
            thinkificUserInfo?.id?.toString(),
            id,
            thinkificUserInfo,
          );
          setActivityData(data);
          document.documentElement.style.setProperty(
            "--card-bg-color",
            data?.type?.color || "#855fa7",
          );
        }
      } catch (error) {
        errorAlert("Error fetching activity data");
      }
      setIsLoader(false);
    };
    fetchData();
  }, [id, errorAlert, setIsLoader, thinkificUserInfo]);

  useEffect(() => {
    const links = document.querySelectorAll(".accordion-section a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }, [activityData]);

  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);
  const handleShareOpen = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setAnchorEl(null);
  };

  const openShare = Boolean(anchorEl);

  useEffect(() => {
    const obj = copyUrlData.find(
      (item) => item.courseId === thinkificUserInfo.courseId,
    );
    if (obj && activityData?.docId) {
      setActivityUrl(`${obj.url}${activityData?.docId}`);
    }
  }, [thinkificUserInfo.courseId, activityData?.docId]);

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const handleCopy = () => {
    navigator.clipboard
      .writeText(activityUrl)
      .then(() => {
        successAlert("URL copied to clipboard!");
      })
      .catch(() => {
        errorAlert("Failed to copy");
      });
  };
  const gradeBandsData = [
    { value: ["Pre-K"], label: "Pre-K" },
    { value: ["K-2", "K-5 OST"], label: "K" },
    { value: ["K-2", "K-5 OST"], label: "1" },
    { value: ["K-2", "K-5 OST"], label: "2" },
    { value: ["3-5", "K-5 OST"], label: "3" },
    { value: ["3-5", "K-5 OST"], label: "4" },
    { value: ["3-5", "K-5 OST", "5-8 OST"], label: "5" },
    { value: ["6-8", "5-8 OST"], label: "6" },
    { value: ["6-8", "5-8 OST"], label: "7" },
    { value: ["6-8", "5-8 OST"], label: "8" },
  ];
  const handleLanguageChange = (newLanguage: IViewSingleEnum) => {
    setLanguage(newLanguage);
  };

  const renderLanguageLink = (
    lang: IViewSingleEnum.english | IViewSingleEnum.spanish,
    label: React.ReactNode,
  ) => (
    <span
      key={lang}
      className={`langStyle ${language === lang && "active"}`}
      role="button"
      tabIndex={0}
      onClick={() => handleLanguageChange(lang)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleLanguageChange(lang);
        }
      }}
    >
      {label}
    </span>
  );

  function findPreviousData(array: string[], currentString: string) {
    const currentIndex = array.indexOf(currentString);
    if (currentIndex === -1 || currentIndex === 0) {
      return null;
    }
    return array[currentIndex - 1];
  }

  function findNextString(array: string[], currentString: string) {
    const currentIndex = array.indexOf(currentString);
    if (currentIndex === -1 || currentIndex === array.length - 1) {
      return null;
    }
    return array[currentIndex + 1];
  }

  const saveActivity = async (isSave: boolean, activityGuidId: string) => {
    setIsLoader(true);

    const response = await saveActivityForUser(
      errorAlert,
      thinkificUserInfo?.id || 0,
      activityGuidId,
      isSave,
    );

    if (response) {
      setActivityData({
        ...(activityData || ({} as IActivity)),
        isSavedActivity: !activityData?.isSavedActivity,
      });
    }

    setIsLoader(false);
  };

  return (
    <section>
      <Container>
        <Grid container spacing={2} className="hide-on-print">
          <Grid item xs={12} md={6} sm={5}>
            <Link
              className="btnLink back-arrow oswald"
              to={location.pathname}
              onClick={() => {
                setActivityId(null);
                if (window.self !== window.top) {
                  const thinkificUserData = { ...thinkificUserInfo };
                  thinkificUserData.activityId = "";
                  setThinkificUserInfo({ ...thinkificUserData });
                }
              }}
            >
              <img
                src="/assets/icons/back-arrow.svg"
                alt="back-arrow"
                className="backArrow"
                style={{ paddingRight: "0.5rem" }}
              />
              Back to Search Results
            </Link>
          </Grid>
          <Grid item xs={12} md={3} sm={3.5}>
            {findPreviousData(searchFilterDetails.activityList, id || "") && (
              <Link
                className="btnLink oswald"
                to={location.pathname}
                onClick={() => {
                  setActivityId(
                    findPreviousData(
                      searchFilterDetails.activityList,
                      id || "",
                    ),
                  );
                }}
              >
                <i className="fa-solid fa-arrow-left" />
                Previous Activity
              </Link>
            )}
          </Grid>
          <Grid item xs={12} md={3} sm={3.5}>
            {findNextString(searchFilterDetails.activityList, id || "") && (
              <Link
                className="btnLink justEnd oswald"
                to={location.pathname}
                onClick={() => {
                  setActivityId(
                    findNextString(searchFilterDetails.activityList, id || ""),
                  );
                }}
              >
                Next Activity <i className="fa-solid fa-arrow-right" />
              </Link>
            )}
          </Grid>
        </Grid>
        <div className="mainActivity whiteSpace">
          {activityData && (
            <Grid
              container
              // fullWidth
              spacing={{ md: 3 }}
              sx={{
                boxShadow: "15px 15px 0px #00000014",
                borderRadius: "40px",
              }}
            >
              <Grid
                item
                md={5}
                sm={5}
                sx={{
                  pb: 3,
                  padding: { xs: "30px" },
                  background: "var(--primary-color)",
                  borderTopLeftRadius: { md: "40px", xs: "40px" },
                  borderTopRightRadius: { xs: "40px", md: "0px", sm: "0px" },
                  borderBottomLeftRadius: { md: "40px", xs: "0px", sm: "40px" },
                }}
              >
                <div className="innerActivity">
                  <h3 className="large_heading textWhite fontHeading">
                    {activityData.name}
                  </h3>
                  <ul className="inlineList hide-on-print">
                    <li className="itemHeader itemHeaderSingleActivity">
                      {!isEmpty(thinkificUserInfo) &&
                        (!activityData.isSavedActivity ? (
                          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                          <i
                            className="far fa-star"
                            onKeyDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveActivity(true, activityData._id);
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveActivity(true, activityData._id);
                            }}
                          />
                        ) : (
                          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                          <i
                            className="fa fa-star saved"
                            onKeyDown={() =>
                              saveActivity(false, activityData._id)
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveActivity(false, activityData._id);
                            }}
                          />
                        ))}
                    </li>
                    <li>
                      <Link to="/" onClick={onPrint}>
                        <img src="/assets/icons/print.svg" alt="print" />
                      </Link>
                    </li>
                    {thinkificUserInfo.courseId && (
                      <li>
                        <Link to="/" onClick={handleShareOpen}>
                          <img src="/assets/icons/share.svg" alt="share" />
                        </Link>
                      </li>
                    )}
                  </ul>
                  <div className="typeSubtype">
                    {activityData.type &&
                      !isFieldEmpty(activityData.type.value) && (
                        <div className="leftSub">
                          <h4 className="regular_heading textWhite">Type</h4>
                          <p>{activityData.type.value}</p>
                        </div>
                      )}
                    {activityData.subType &&
                      !isFieldEmpty(activityData.subType.value) && (
                        <div className="leftSub removeBar">
                          <h4 className="regular_heading textWhite">SUBTYPE</h4>
                          <p>{activityData.subType.value}</p>
                        </div>
                      )}
                  </div>
                  {!isFieldEmpty(
                    activityData.skillsAndConceptsDetailedHTML,
                  ) && (
                    <div className="accordianItem marginStyle">
                      <h4 className="regular_heading centerText textWhite">
                        SKILLS & CONCEPTS
                      </h4>
                      <div
                        className="accordion-section textWhite centerList"
                        dangerouslySetInnerHTML={{
                          __html: activityData.skillsAndConceptsDetailedHTML,
                        }}
                      />
                    </div>
                  )}

                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    {!isFieldEmpty(activityData.gradeBands) && (
                      <div className="gradesItems">
                        <h4 className="regular_heading textWhite">Grades</h4>
                        <ul className="centerList">
                          {gradeBandsData.map((gradeBand) => {
                            const isGradeBandAvailable =
                              activityData &&
                              activityData.gradeBands &&
                              activityData.gradeBands.some((band) =>
                                gradeBand.value.includes(band.value),
                              );

                            return (
                              <li
                                className={`${
                                  isGradeBandAvailable ? "active" : "inactive"
                                }`}
                              >
                                {gradeBand.label}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    <div className="typeSubtype mt50">
                      {!isFieldEmpty(activityData.spaceRequirement.value) && (
                        <div className="leftSub removeBar">
                          <h4 className="regular_heading textWhite">SPACE</h4>
                          <p> {activityData.spaceRequirement.value}</p>
                        </div>
                      )}
                      {!isFieldEmpty(activityData.numberOfPlayers.value) && (
                        <div className="leftSub removeBar">
                          <h4 className="regular_heading textWhite">
                            #OF PLAYERS
                          </h4>
                          <p>{activityData.numberOfPlayers.value}</p>
                        </div>
                      )}
                    </div>
                    {!isFieldEmpty(activityData.subStandardsAlignments) && (
                      <div className="gradesItems standardGrade">
                        <h4 className="regular_heading textWhite">
                          STANDARDS ALIGNMENTS
                          <span
                            onClick={handleStandardAlignment}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" || event.key === " ") {
                                handleStandardAlignment(event);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-circle-info" />
                          </span>
                        </h4>
                        <p className="mt20">
                          {activityData.subStandardsAlignments &&
                            activityData.subStandardsAlignments.map(
                              (alignment) => <p key={alignment}>{alignment}</p>,
                            )}
                        </p>
                      </div>
                    )}
                    {!isFieldEmpty(activityData.selCompetencies) && (
                      <div className="gradesItems standardGrade">
                        <h4 className="regular_heading textWhite">
                          SEL ALIGNMENTS{" "}
                          <span
                            onClick={handleSelAlignment}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" || event.key === " ") {
                                handleSelAlignment(event);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-circle-info" />
                          </span>
                        </h4>
                        <p className="mt20">
                          {activityData.selCompetencies &&
                            activityData.selCompetencies.map(
                              (selCompetency) => (
                                <p key={selCompetency._id}>
                                  {selCompetency.value}
                                </p>
                              ),
                            )}
                        </p>
                      </div>
                    )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: { xs: "block", md: "none" },
                    }}
                    className="hide-on-print"
                  >
                    <Button
                      sx={{
                        mt: "30px",
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "Montserrat, sans-serif",
                        color: "var(--yellow-color)",
                      }}
                      onClick={handleToggle}
                    >
                      {isOpen ? "Collapse" : "Expand"}
                    </Button>
                    {isOpen ? (
                      <i className="fa fa-chevron-up textYellow" />
                    ) : (
                      <i className="fa fa-chevron-down textYellow" />
                    )}{" "}
                  </Box>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    {!isFieldEmpty(activityData.gradeBands) && (
                      <div className="gradesItems">
                        <h4 className="regular_heading centerText textWhite">
                          Grades
                        </h4>
                        <ul className="centerList">
                          {gradeBandsData.map((gradeBand) => {
                            const isGradeBandAvailable =
                              activityData &&
                              activityData.gradeBands &&
                              activityData.gradeBands.some((band) =>
                                gradeBand.value.includes(band.value),
                              );

                            return (
                              <li
                                className={`${
                                  isGradeBandAvailable ? "active" : ""
                                }`}
                              >
                                {gradeBand.label}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    <div className="typeSubtype mt50">
                      {!isFieldEmpty(activityData.spaceRequirement.value) && (
                        <div className="leftSub removeBar">
                          <h4 className="regular_heading textWhite">SPACE</h4>
                          <p> {activityData.spaceRequirement.value}</p>
                        </div>
                      )}
                      {!isFieldEmpty(activityData.numberOfPlayers.value) && (
                        <div className="leftSub removeBar">
                          <h4 className="regular_heading textWhite">
                            #OF PLAYERS
                          </h4>
                          <p>{activityData.numberOfPlayers.value}</p>
                        </div>
                      )}
                    </div>
                    {!isFieldEmpty(activityData.subStandardsAlignments) && (
                      <div className="gradesItems standardGrade">
                        <h4 className="regular_heading textWhite">
                          STANDARDS ALIGNMENTS
                          <span
                            onClick={handleStandardAlignment}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" || event.key === " ") {
                                handleStandardAlignment(event);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-circle-info" />
                          </span>
                        </h4>
                        <p className="mt20">
                          {activityData.subStandardsAlignments &&
                            activityData.subStandardsAlignments.map(
                              (alignment) => <p key={alignment}>{alignment}</p>,
                            )}
                        </p>
                      </div>
                    )}
                    {!isFieldEmpty(activityData.selCompetencies) && (
                      <div className="gradesItems standardGrade">
                        <h4 className="regular_heading textWhite">
                          SEL ALIGNMENTS{" "}
                          <span
                            onClick={handleSelAlignment}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" || event.key === " ") {
                                handleSelAlignment(event);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-circle-info" />
                          </span>
                        </h4>
                        <p className="mt20">
                          {activityData.selCompetencies &&
                            activityData.selCompetencies.map(
                              (selCompetency) => (
                                <p key={selCompetency._id}>
                                  {selCompetency.value}
                                </p>
                              ),
                            )}
                        </p>
                      </div>
                    )}
                  </Collapse>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                sx={{
                  p: 3,
                  background: "#fff",
                  padding: {
                    xs: "20px",
                    md: "24px 30px !important",
                    lg: "24px 50px !important",
                  },
                  borderTopRightRadius: { xs: "0px", md: "40px", sm: "40px" },
                  borderBottomLeftRadius: { md: "0px", xs: "40px" },
                  borderBottomRightRadius: { md: "40px", xs: "40px" },
                }}
              >
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <div className="rightViewSingleActivity rightText">
                    {!isFieldEmpty(
                      activityData.videoSpanish || activityData.videoEnglish,
                    ) && (
                      <div className="hide-on-print">
                        <div className="activityRightList">
                          <ul>
                            <LanguageIcon className="globeIcon" />
                            <li>
                              {renderLanguageLink(
                                IViewSingleEnum.english,
                                IViewSingleEnum.english,
                              )}
                            </li>
                            {" | "}
                            <li>
                              {renderLanguageLink(
                                IViewSingleEnum.spanish,
                                IViewSingleEnum.spanish,
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="videoActivity centerList">
                          <iframe
                            src={`https://player.vimeo.com/video/${
                              language === IViewSingleEnum.spanish
                                ? activityData?.videoSpanish
                                : activityData?.videoEnglish
                            }?badge=0&vimeo_logo=false`}
                            allow="autoplay; fullscreen; picture-in-picture"
                            title={
                              activityData &&
                              `${activityData.name} - CATCH Physical Activity Demo Video`
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="accordianItem">
                      {!isFieldEmpty(activityData.equipmentDescription) && (
                        <Accordion
                          expanded={expanded}
                          sx={{
                            boxShadow: "none",
                          }}
                          onChange={handleAccordionChange}
                        >
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  fontSize: { xs: "0px", md: "0px" },
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            Equipment
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.equipmentDescription,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                    {!isFieldEmpty(activityData.organization) && (
                      <div className="accordianItem">
                        <Accordion
                          expanded={expanded}
                          sx={{
                            boxShadow: "none",
                          }}
                          onChange={handleAccordionChange}
                        >
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  fontSize: { xs: "0px", md: "0px" },
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            ORGANIZATION
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.organization,
                              }}
                            />
                            {activityData.diagram.map((item: string) => (
                              <img
                                src={item}
                                alt="Organization's Logo"
                                className="orgImage"
                              />
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                    {!isFieldEmpty(activityData.instructions) && (
                      <div className="accordianItem">
                        <Accordion
                          expanded={expanded}
                          sx={{
                            boxShadow: "none",
                          }}
                          onChange={handleAccordionChange}
                        >
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  fontSize: { xs: "0px", md: "0px" },
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            INSTRUCTIONS
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.instructions,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}

                    <div className="accordianItem removeSpace">
                      {!isFieldEmpty(activityData.teachingSuggestions) && (
                        <Accordion sx={{ boxShadow: "none", px: 0 }}>
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "35px",
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            <img
                              className="iconTabs"
                              src="/assets/icons/teaching-suggestions.svg"
                              alt="Technical Suggetions"
                            />{" "}
                            TEACHING SUGGESTIONS
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section show-on-print"
                              dangerouslySetInnerHTML={{
                                __html: activityData.teachingSuggestions,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {!isFieldEmpty(activityData.variations) && (
                        <Accordion sx={{ boxShadow: "none", px: 0 }}>
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "35px",
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            <img
                              className="iconTabs"
                              src="/assets/icons/variations.svg"
                              alt="Variation"
                            />{" "}
                            VARIATIONS
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.variations,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {!isFieldEmpty(activityData.differentiation) && (
                        <Accordion sx={{ boxShadow: "none", px: 0 }}>
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "35px",
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            <img
                              className="iconTabs"
                              src="/assets/icons/age-differentiation.svg"
                              alt="Age Different"
                            />{" "}
                            AGE DIFFERENTIATION
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.differentiation,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {!isFieldEmpty(activityData.selReinforcement) && (
                        <Accordion sx={{ boxShadow: "none", px: 0 }}>
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "35px",
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            <img
                              className="iconTabs"
                              src="/assets/icons/sel-reinforcement.svg"
                              alt="Sel Reinforcement"
                            />{" "}
                            SEL REINFORCEMENT
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.selReinforcement,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {!isFieldEmpty(activityData.inclusionAdaptation) && (
                        <Accordion sx={{ boxShadow: "none", px: 0 }}>
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "35px",
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            <img
                              className="iconTabs"
                              src="/assets/icons/adaptation.svg"
                              alt="Inclusion"
                            />{" "}
                            INCLUSION ADAPTATION
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.inclusionAdaptation,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {!isFieldEmpty(activityData.virtualAdaption) && (
                        <Accordion sx={{ boxShadow: "none", px: 0 }}>
                          <AccordionSummary
                            sx={{
                              textTransform: "uppercase",
                              px: 0,
                              mx: "15px",
                              fontSize: "16px",
                              fontWeight: "800",
                              color: "var(--primary-color)",
                              borderBottom: "1px solid #dbe7ef",
                            }}
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "35px",
                                }}
                              />
                            }
                            id="panel1-header"
                          >
                            <img
                              className="iconTabs"
                              src="/assets/icons/virtual-adaptation.svg"
                              alt="Inclusion"
                            />{" "}
                            VIRTUAL ADAPTATION
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              fontSize: "16px",
                              color: "#36394D",
                              pt: "25px",
                              px: { lg: "20px" },
                            }}
                          >
                            <div
                              className="accordion-section"
                              dangerouslySetInnerHTML={{
                                __html: activityData.virtualAdaption,
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  </div>
                </Box>
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  {!isFieldEmpty(
                    activityData.videoSpanish || activityData.videoEnglish,
                  ) && (
                    <div className="hidden-on-print hide-on-print">
                      {" "}
                      <div className="activityRightList">
                        <ul>
                          <LanguageIcon className="globeIcon" />
                          <li>
                            {renderLanguageLink(
                              IViewSingleEnum.english,
                              IViewSingleEnum.english,
                            )}
                          </li>
                          {" | "}
                          <li>
                            {renderLanguageLink(
                              IViewSingleEnum.spanish,
                              IViewSingleEnum.spanish,
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="videoActivity centerList">
                        <iframe
                          src={`https://player.vimeo.com/video/${
                            language === IViewSingleEnum.spanish
                              ? activityData?.videoSpanish
                              : activityData?.videoEnglish
                          }?badge=0&vimeo_logo=false`}
                          allow="autoplay; fullscreen; picture-in-picture"
                          title={
                            activityData &&
                            `${activityData.name} - CATCH Physical Activity Demo Video`
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="removeSpace">
                    {!isFieldEmpty(activityData.equipmentDescription) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/equipment.svg"
                            alt="Equipment"
                          />
                          EQUIPMENT
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.equipmentDescription,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.organization) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/organization.svg"
                            alt="Organization"
                          />{" "}
                          ORGANIZATION
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.organization,
                            }}
                          />
                          {activityData.diagram.map((item: string) => (
                            <img
                              src={item}
                              alt="Organization's Logo"
                              className="orgImage"
                            />
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.instructions) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/instructions.svg"
                            alt="Instruction"
                          />
                          INSTRUCTIONS
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.instructions,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.teachingSuggestions) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/teaching-suggestions.svg"
                            alt="Teaching Suggetion"
                          />{" "}
                          TEACHING SUGGESTIONS
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.teachingSuggestions,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.variations) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/variations.svg"
                            alt="Variation"
                          />{" "}
                          VARIATIONS
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.variations,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.differentiation) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/age-differentiation.svg"
                            alt="Variation"
                          />{" "}
                          AGE DIFFERENTIATION
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.differentiation,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.selReinforcement) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/sel-reinforcement.svg"
                            alt="Sel Reinforecement"
                          />{" "}
                          SEL REINFORCEMENT
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.selReinforcement,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.inclusionAdaptation) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/adaptation.svg"
                            alt="Inclusion Adaptation"
                          />{" "}
                          INCLUSION ADAPTATION
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.inclusionAdaptation,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                    {!isFieldEmpty(activityData.virtualAdaption) && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          sx={{
                            fontSize: { xs: "15px", md: "20px" },
                            padding: { xs: "2px 2px", md: "0" },
                            textTransform: "uppercase",
                            fontWeight: "600",
                            color: "var(--primary-color)",
                            borderBottom: "1px solid #dbe7ef",
                          }}
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: { xs: "28px", md: "35px" },
                              }}
                            />
                          }
                          id="panel1-header"
                        >
                          <img
                            className="iconTabs"
                            src="/assets/icons/virtual-adaptation.svg"
                            alt="Virtual"
                          />{" "}
                          VIRTUAL ADAPTATION
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            color: "#36394D",
                            fontSize: { xs: "16px", md: "16px" },
                            pt: "25px",
                          }}
                        >
                          <div
                            className="accordion-section"
                            dangerouslySetInnerHTML={{
                              __html: activityData.virtualAdaption,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          )}
          <Popover
            id={standardAlignment}
            open={standardOpen}
            anchorEl={standardAlign}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "1px",
                right: "5px",
                color: "#adacb4",
                width: "20px",
              }}
              onClick={handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <p style={{ padding: "25px", width: "100%", maxWidth: "25rem" }}>
              <span style={{ fontWeight: "600" }}>Source:</span> SHAPE
              America&apos;s 2013 National Standards & Grade-Level Outcomes for
              K-12 Physical Education. <i>(2024 alignments coming.)</i>
              <br />
              <br />
              <button
                type="button"
                className="customSearchButton"
                onClick={handleAlignButtonClick}
              >
                More Info
                <span>
                  <ForwardIcon className="search-arrow" />
                </span>
              </button>
            </p>
          </Popover>
          <Popover
            id={selAlignment}
            open={selOpen}
            anchorEl={selAlign}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "1px",
                right: "5px",
                color: "#adacb4",
                width: "20px",
              }}
              onClick={handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <p style={{ padding: "25px", width: "100%", maxWidth: "25rem" }}>
              <span style={{ fontWeight: "600" }}>Source:</span> Collaborative
              for Academic, Social and Emotional Learning (CASEL)
              <br />
              <br />
              <button
                type="button"
                className="customSearchButton"
                onClick={handleSelButtonClick}
              >
                More Info
                <span>
                  <ForwardIcon className="search-arrow" />
                </span>
              </button>
            </p>
          </Popover>
          <Popover
            open={openShare}
            anchorEl={anchorEl}
            onClose={handleShareClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "1px",
                right: "5px",
                color: "#adacb4",
                width: "20px",
              }}
              onClick={handleShareClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <p style={{ padding: "20px", width: "100%", maxWidth: "30rem" }}>
              <span style={{ color: "#0063ab", fontWeight: "600" }}>
                Copy Activity URL
              </span>{" "}
              <br />
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 400,
                  background: "#ebf0f2",
                  paddingRight: "12px",
                  borderRadius: "20px",
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    mr: 2,
                    flex: 1,
                    fontFamily: "Montserrat",
                  }}
                  placeholder="URL"
                  inputProps={{ "aria-label": "Copy URL" }}
                  value={activityUrl}
                />

                {/* <Divider sx={{ height: 45 }} orientation="vertical" /> */}
                {/* <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={handleCopy}
                >
                  <ContentPasteGoIcon />
                </IconButton> */}
              </Paper>
            </p>
          </Popover>
        </div>
      </Container>
    </section>
  );
}

export default ViewSingleActivity;
