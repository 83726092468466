const SearchActivityStyles = {
  boxContainer: {
    fontFamily: "Montserrat",
    position: "sticky",
    display: "block",
    width: "100%",
  },
  savedActivity: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    color: "white",
    justifyContent: "flex-end",
    background: "#0163ac",
    padding: "10px",
    fontWeight: 900,
  },
  savedActivityIcon: {
    fontSize: "14px",
    display: "flex",
    gap: "6px",
    color: "white",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  typoBox: {
    background: "#054672",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  favouriteBoxContainer: {
    flexGrow: 1,
    padding: 5,
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "space-between",
  },

  iconButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    padding: "5px",
  },
  cardItem: {
    maxWidth: "250px !important",
    minWidth: "210px !important",
    flexBasis: "30%",
    borderRadius: 3,
    border: "1.5px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
    position: "relative",
    cursor: "pointer",
    height: "fit-content",
  },
  cardContent: {
    textAlign: "left",
    color: "black",
    "& a": {
      color: "black",
      textDecoration: "none",
    },
  },
  typoTitle: {
    color: "#0063ab",
    textTransform: "capitalize",
    fontWeight: "900",
    fontFamily: "Montserrat Regular",
  },
  modalBox: {
    display: "flex",
    minWidth: "fit-content",
  },
  activityInfo: {
    color: "#fff",
    fontWeight: "500",
    fontSize: "18px",
    padding: "15px",
    textTransform: "lowercase",
    display: "flex",
    justifyContent: "center",
  },
  modalLabels: {
    color: "#fff",
    fontWeight: "500",
    fontSize: "18px",
    padding: "15px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 0.6,
    cursor: "pointer",
    fontFamily: "Oswald",
  },
  typoSubTitle: {
    color: "#0063ab",
    cursor: "pointer",
    fontWeight: "900",
    letterSpacing: "1",
    fontSize: "12px",
    marginTop: "25px",
    fontFamily: "Montserrat Regular",
  },
  starIcon: {
    color: "#fff",
    width: "0.7em",
    height: "0.7em",
  },
  editIcon: {
    fontSize: "1.1rem",
  },
  sillsAndConceptsItem: {
    background: "#0063ab",
    display: "inline-block",
    padding: "2px 10px",
    color: "white",
    borderRadius: "3px",
    textTransform: "capitalize",
    fontSize: "14px",
    margin: "2px 4px 2px 0px",
  },
  subTypeItem: {
    marginTop: "8px",
  },
};
export default SearchActivityStyles;
