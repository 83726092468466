import { Box } from "@mui/material";
import React from "react";
import AlertServiceStyles from "../styles/alertService";
import IAlertType from "../enums/AlertEnum";

interface IAlertService {
  message: string;
  type: IAlertType;
}

const AlertService = ({ message, type }: IAlertService) => {
  return (
    <Box
      sx={{
        ...AlertServiceStyles.alert,
        backgroundColor: type === IAlertType.SUCCESS ? "#28a745" : "#dc3545",
      }}
    >
      {message}
    </Box>
  );
};

export default AlertService;
