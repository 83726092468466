const HeaderComponentStyles = {
  grid: {
    display: "flex",
  },
  navListItem: {
    padding: "0px",
  },
  navListItemButton: {
    padding: "10px 45px",
    textAlign: "left",
  },
};

export default HeaderComponentStyles;
