enum IAdvancedSearchEnum {
  name = "name",
  type = "type",
  subType = "subType",
  gradeBands = "gradeBands",
  skillsAndConcepts = "skillsAndConcepts",
  numberOfPlayers = "numberOfPlayers",
  spaceRequirement = "spaceRequirement",
  equipment = "equipment",
  isEquipmentRequired = "isEquipmentRequired",
  progressionLevel = "progressionLevel",
  shapePEStandards = "shapePEStandards",
  videoDemo = "videoDemo",
  virtualAdaptation = "virtualAdaptation",
  selReinforcement = "selReinforcement",
  inclusionGuidance = "inclusionGuidance",
  isSearchInSavedActivities = "isSearchInSavedActivities",
}

export default IAdvancedSearchEnum;
