import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Paper,
  Autocomplete,
  ToggleButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import "./edit-single-activity.scss";
import TabComponent from "../richtext/TabComponent";
import SingleFileUploader from "../file-upload/single-file-upload ";
import EditSingleStyles from "../../../styles/editSingleActivity";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";
import { fetchActivityData } from "../../../APIs/viewSingleActivityApi";
import { getAllMasterData } from "../../../APIs/masterDataApi";
import { updateActivity } from "../../../APIs/activitiesApi";
import AlertContext from "../../../context/alertContext";
import LoaderContext from "../../../context/loaderContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditSingleActivity() {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [types, setTypes] = useState<BaseResponse[]>([]);
  const [subType, setSubType] = useState<BaseResponse[]>([]);
  const [gradeBands, setGradeBands] = useState<BaseResponse[]>([]);
  const [noOfPlayers, setNoOfPlayers] = useState<BaseResponse[]>([]);
  const [spaceRequirement, setSpaceRequirement] = useState<BaseResponse[]>([]);
  const [progressionLevel, setProgressionLevel] = useState<BaseResponse[]>([]);
  const [skillsConcepts, setSkillsConcepts] = useState<BaseResponse[]>([]);
  const [standards, setStandards] = useState<BaseResponse[]>([]);
  const [selCompetencies, setSelCompetencies] = useState<BaseResponse[]>([]);
  const [equipment, setEquipment] = useState<BaseResponse[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [tabData, setTabData] = React.useState<string[]>([]);
  const [activityData, setActivityData] = useState<IActivity | null>(null);

  const [videoEnglishUploader, setVideoEnglishUploader] = useState(false);
  const [videoSpanishUploader, setVideoSpanishUploader] = useState(false);
  const [imageUploader, setImageUploader] = useState(false);
  const [uploadedDiagrams, setUploadedDiagrams] = useState<File[]>([]);

  const [activityFormData, setActivityFormData] = useState<IActivityFormData>({
    name: "",
    isFreeSample: "Yes",
    type: null,
    subType: [],
    gradeBands: [],
    numberOfPlayers: null,
    spaceRequirement: null,
    standardsAlignments: [],
    subStandardsAlignments: "",
    selCompetencies: [],
    progressionLevel: null,
    skillsAndConcepts: [],
    videoEnglish: "",
    videoSpanish: "",
    diagram: [],
    inclusionGuideCrosslink: "",
    hasSpanishTranslation: "False",
    isEquipmentRequired: "False",
    equipment: [],
  });

  const { successAlert, errorAlert } = useContext<IAlertContext>(AlertContext);
  const { setIsLoader } = useContext<ILoader>(LoaderContext);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const data = await getAllMasterData(errorAlert);
        if (data) {
          const dataByType = (type: string) =>
            data.filter((item) => item.type === type);
          setTypes(dataByType("type"));
          setSubType(dataByType("subType"));
          setGradeBands(dataByType("gradeBands"));
          setNoOfPlayers(dataByType("numberOfPlayers"));
          setSpaceRequirement(dataByType("spaceRequirement"));
          setProgressionLevel(dataByType("progressionLevel"));
          setSkillsConcepts(dataByType("skillsAndConcepts"));
          setStandards(dataByType("standardsAlignments"));
          setSelCompetencies(dataByType("selCompetencies"));
          setEquipment(dataByType("equipment"));
        }
      } catch (error) {
        errorAlert("Error fetching master data");
      }
    };

    const fetchActivityDataById = async () => {
      setIsLoader(true);
      try {
        if (!id) {
          setActivityData(null);
          return;
        }
        const activityDetails = await fetchActivityData(id);
        setActivityData(activityDetails);
        setActivityFormData((prev) => ({
          ...prev,
          name: activityDetails.name,
          isFreeSample: activityDetails.isFreeSample ? "Yes" : "No",
          type: activityDetails.type || null,
          subType: [
            {
              ...activityDetails.subType,
              value: activityDetails.subType.value,
            },
          ],
          gradeBands: activityDetails.gradeBands,
          numberOfPlayers: activityDetails.numberOfPlayers,
          spaceRequirement: activityDetails.spaceRequirement,
          standardsAlignments: activityDetails.standardsAlignments,
          subStandardsAlignments:
            activityDetails.subStandardsAlignments.join(", "),
          selCompetencies: activityDetails.selCompetencies,
          progressionLevel: activityDetails.progressionLevel,
          skillsAndConcepts: activityDetails.skillsAndConcepts,
          videoEnglish: activityDetails.videoEnglish || "",
          videoSpanish: activityDetails.videoSpanish || "",
          diagram: activityDetails.diagram,
          inclusionGuideCrosslink: activityDetails.inclusionGuideCrosslink,
          hasSpanishTranslation: activityDetails.hasSpanishTranslation
            ? "True"
            : "False",
          isEquipmentRequired: activityDetails.isEquipmentRequired
            ? "True"
            : "False",
          equipment: activityDetails.equipment,
        }));
        setTabData([
          activityDetails.equipmentDescription || "",
          activityDetails.organization || "",
          activityDetails.instructions || "",
          activityDetails.teachingSuggestions || "",
          activityDetails.variations || "",
          activityDetails.differentiation || "",
          activityDetails.selReinforcement || "",
          activityDetails.inclusionAdaptation || "",
          activityDetails.virtualAdaption || "",
        ]);
      } catch (error) {
        errorAlert("Error fetching activity data");
      }
      setIsLoader(false);
    };

    fetchActivityDataById();
    fetchMasterData();
  }, [errorAlert, id, setIsLoader]);

  const toggleEnglishUploader = () => {
    setVideoEnglishUploader((prevShowUploader) => !prevShowUploader);
  };
  const toggleSpanishUploader = () => {
    setVideoSpanishUploader((prevShowUploader) => !prevShowUploader);
  };
  const toggleImageUploader = () => {
    if (imageUploader) {
      setActivityFormData((prevData) => ({
        ...prevData,
        diagram: [],
      }));
    }
    setUploadedDiagrams([]);
    setImageUploader((prevShowUploader) => !prevShowUploader);
  };

  const handleTabDataChange = (newContent: string) => {
    const updatedTabData = [...tabData];
    updatedTabData[tabValue] = newContent;
    setTabData(updatedTabData);
  };

  const handleDiagramUpload = (files: File[]) => {
    setUploadedDiagrams(files);
  };

  const handleActivityFormStateChange = (
    data: Record<
      string,
      string | string[] | BaseResponse | BaseResponse[] | null
    >,
  ) => {
    setActivityFormData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleAllRadioChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;

    if (type === "isFreeSample")
      handleActivityFormStateChange({
        isFreeSample: value,
      });
    if (type === "hasSpanishTranslation")
      handleActivityFormStateChange({
        hasSpanishTranslation: value,
      });
    if (type === "isEquipmentRequired")
      handleActivityFormStateChange({
        isEquipmentRequired: value,
      });
  };

  const handleAllCheckboxChange = (field: string, item: BaseResponse) => {
    const getUpdatedSelections = (items: BaseResponse[]): BaseResponse[] => {
      return items.some((selected) => selected._id === item._id)
        ? items.filter((selected) => selected._id !== item._id)
        : [...items, item];
    };

    if (field === "gradeBands")
      handleActivityFormStateChange({
        gradeBands: getUpdatedSelections(activityFormData.gradeBands),
      });

    if (field === "standardsAlignments")
      handleActivityFormStateChange({
        standardsAlignments: getUpdatedSelections(
          activityFormData.standardsAlignments,
        ),
      });

    if (field === "selCompetencies")
      handleActivityFormStateChange({
        selCompetencies: getUpdatedSelections(activityFormData.selCompetencies),
      });
  };

  const handleTextFeildChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const trimmedValue = value.trim();

    if (field === "name") handleActivityFormStateChange({ name: trimmedValue });
    if (field === "videoEnglish")
      handleActivityFormStateChange({ videoEnglish: value });
    if (field === "videoSpanish")
      handleActivityFormStateChange({ videoSpanish: value });
    if (field === "subStandardsAlignments")
      handleActivityFormStateChange({ subStandardsAlignments: value });
    if (field === "inclusionGuideCrosslink")
      handleActivityFormStateChange({ inclusionGuideCrosslink: trimmedValue });
  };

  const validateForm = (): string[] => {
    const errors: string[] = [];

    if (!activityFormData.name) errors.push("Activity name is required.");
    if (!activityFormData.type) errors.push("Activity type is required.");
    if (!activityFormData.gradeBands.length)
      errors.push("At least one grade band is required.");
    if (!activityFormData.numberOfPlayers)
      errors.push("No. of Players  is required.");
    if (!activityFormData.spaceRequirement)
      errors.push("Space Requirement  is required.");
    if (!activityFormData.skillsAndConcepts.length)
      errors.push("Skills and concepts  is required.");
    if (!tabData[1]) errors.push("Organization is required.");
    if (!tabData[2]) errors.push("Instructions are required.");
    if (!activityFormData.isEquipmentRequired)
      errors.push("Equipment required should be set to something.");
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (errors.length > 0) {
      errors.reverse().forEach((error) => errorAlert(error));
      return;
    }

    if (!activityData || !id) return;

    try {
      const updatedData: Partial<IActivity> = {
        ...activityData,
        ...activityFormData,
        name: activityFormData.name.trim(),
        isFreeSample: activityFormData.isFreeSample === "Yes",
        type: activityFormData.type || activityData.type,
        subType: activityFormData.subType[0] || activityData.subType,
        numberOfPlayers:
          activityFormData.numberOfPlayers || activityData.numberOfPlayers,
        spaceRequirement:
          activityFormData.spaceRequirement || activityData.spaceRequirement,
        subStandardsAlignments: activityFormData.subStandardsAlignments
          .split(",")
          .map((item) => item.trim()),
        progressionLevel:
          activityFormData.progressionLevel || activityData.progressionLevel,
        hasSpanishTranslation:
          activityFormData.hasSpanishTranslation === "True",
        isEquipmentRequired: activityFormData.isEquipmentRequired === "True",
        equipment: activityFormData.equipment || activityData.equipment,
        diagram:
          uploadedDiagrams.length > 0
            ? uploadedDiagrams.map((file) => URL.createObjectURL(file))
            : activityFormData.diagram,
        equipmentDescription: tabData[0],
        organization: tabData[1],
        instructions: tabData[2],
        teachingSuggestions: tabData[3],
        variations: tabData[4],
        differentiation: tabData[5],
        selReinforcement: tabData[6],
        inclusionAdaptation: tabData[7],
        virtualAdaption: tabData[8],
      };

      const updatedDataAPI: {
        [key: string]: string | number | boolean | string[] | string;
      } = {};

      Object.entries(updatedData).forEach(([key, value]) => {
        if (
          [
            "name",
            "type",
            "subType",
            "numberOfPlayers",
            "spaceRequirement",
            "progressionLevel",
            "videoEnglish",
            "videoSpanish",
            "inclusionGuideCrosslink",
            "organization",
            "equipment",
            "equipmentDescription",
            "instructions",
            "teachingSuggestions",
            "variations",
            "differentiation",
            "selReinforcement",
            "inclusionAdaptation",
            "virtualAdaption",
          ].includes(key)
        ) {
          updatedDataAPI[key] =
            value && typeof value === "object" && "_id" in value
              ? (value as BaseResponse)._id
              : (value as string | number | boolean);
        } else if (
          [
            "gradeBands",
            "standardsAlignments",
            "subStandardsAlignments",
            "selCompetencies",
            "skillsAndConcepts",
            "diagram",
            "equipment",
            "inclusionGuideCrosslink",
          ].includes(key)
        ) {
          updatedDataAPI[key] = (value as BaseResponse[]).map((item) =>
            typeof item === "object" && "_id" in item
              ? item._id
              : (item as unknown as string),
          );
        } else {
          updatedDataAPI[key] = value as string | string[] | number | boolean;
        }
      });

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ...updatedDataAPI,
          isFreeSample: updatedDataAPI.isFreeSample ? "true" : "false",
          hasSpanishTranslation: updatedDataAPI.hasSpanishTranslation
            ? "true"
            : "false",
          isEquipmentRequired: updatedDataAPI.isEquipmentRequired
            ? "true"
            : "false",
        }),
      );

      uploadedDiagrams.forEach((item) => {
        formData.append("diagram", item);
      });

      const updatedActivity = await updateActivity(errorAlert, id, formData);
      if (updatedActivity) {
        setActivityData(updatedActivity);
        successAlert("Activity updated successfully");
      }
    } catch (error) {
      errorAlert("Error updating activity");
    }
  };

  return (
    <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={EditSingleStyles.typoLabel}>
          Edit Activity
        </Typography>
      </Grid>
      <Paper sx={{ padding: 2 }}>
        {activityData && (
          <>
            <Grid container>
              <Grid item xs={12} sm={6} md={9} className="gridContainer">
                <Grid container className="borderBottomStyle">
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span>Name
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      className="custom-width"
                      disabled={Boolean(activityData.name)}
                      value={activityFormData.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleTextFeildChange("name", e);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                    className="checkbox"
                  >
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Free Sample
                    </Typography>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup className="radioLabels">
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={activityFormData.isFreeSample}
                          onChange={(event) =>
                            handleAllRadioChange("isFreeSample", event)
                          }
                          row
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span> Type
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="type-autocomplete"
                      options={types}
                      className="custom-width"
                      getOptionLabel={(option) => option.value}
                      defaultValue={activityFormData?.type || null}
                      onChange={(_, newValue) => {
                        handleActivityFormStateChange({ type: newValue });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Sub-Type
                    </Typography>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={subType}
                      className="custom-width"
                      value={activityFormData?.subType}
                      getOptionLabel={(option: BaseResponse) => option.value}
                      onChange={(_, newValue) => {
                        handleActivityFormStateChange({ subType: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      classes={{
                        option: "autocomplete-option",
                        noOptions: "autocomplete-option",
                      }}
                      ChipProps={{
                        className: "autocomplete-chip",
                        variant: "filled",
                        deleteIcon: (
                          <CancelRoundedIcon className="autocomplete-delete-icon" />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                    className="checkbox"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span> Grade Bands
                    </Typography>

                    <FormControl
                      sx={EditSingleStyles.checkboxLabelMargin}
                      component="fieldset"
                      variant="standard"
                      size="small"
                    >
                      <FormGroup row>
                        {gradeBands.map((band) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={activityFormData.gradeBands.some(
                                  (selected) => selected._id === band._id,
                                )}
                                onChange={() =>
                                  handleAllCheckboxChange("gradeBands", band)
                                }
                                name={band.value}
                              />
                            }
                            label={
                              <span className="formLabel">{band.value}</span>
                            }
                            key={band._id}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container className="borderBottomStyle">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                  >
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span> No.of players
                    </Typography>

                    <Autocomplete
                      disablePortal
                      id="type-autocomplete"
                      options={noOfPlayers}
                      className="custom-width"
                      getOptionLabel={(option) => option.value}
                      defaultValue={activityFormData?.numberOfPlayers || null}
                      onChange={(_, newValue) => {
                        handleActivityFormStateChange({
                          numberOfPlayers: newValue,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                  >
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span> Space Requirements
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="type-autocomplete"
                      options={spaceRequirement}
                      className="custom-width"
                      getOptionLabel={(option) => option.value}
                      defaultValue={activityFormData?.spaceRequirement || null}
                      onChange={(_, newValue) => {
                        setActivityFormData((prev) => ({
                          ...prev,
                          spaceRequirement: newValue,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
                <Grid container className="borderBottomStyle">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                    className="checkbox"
                  >
                    <Typography
                      variant="subtitle2"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Standards Main Alignment
                    </Typography>

                    <FormControl
                      sx={EditSingleStyles.radioLabels}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup row>
                        {standards.map((standard) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={activityFormData.standardsAlignments.some(
                                  (selected) => selected._id === standard._id,
                                )}
                                onChange={() =>
                                  handleAllCheckboxChange(
                                    "standardsAlignments",
                                    standard,
                                  )
                                }
                                name={standard.value}
                              />
                            }
                            label={
                              <span className="formLabel">
                                {standard.value}
                              </span>
                            }
                            key={standard._id}
                          />
                        ))}
                      </FormGroup>{" "}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Sub-Standards Main Alignment
                    </Typography>

                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      className="custom-width"
                      value={activityFormData.subStandardsAlignments}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleTextFeildChange("subStandardsAlignments", e);
                      }}
                      placeholder="Enter multiple values with separated by comma"
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="checkbox">
                    <Typography
                      variant="subtitle2"
                      ml={1}
                      sx={EditSingleStyles.typoLabel}
                    >
                      SEL Competencies
                    </Typography>
                    <FormControl
                      sx={EditSingleStyles.radioLabels}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup row>
                        {selCompetencies.map((selCompetency) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={activityFormData.selCompetencies.some(
                                  (selected) =>
                                    selected._id === selCompetency._id,
                                )}
                                onChange={() =>
                                  handleAllCheckboxChange(
                                    "selCompetencies",
                                    selCompetency,
                                  )
                                }
                                name={selCompetency.value}
                              />
                            }
                            label={
                              <span className="formLabel">
                                {selCompetency.value}
                              </span>
                            }
                            key={selCompetency._id}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container className="borderBottomStyle">
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Progression level
                    </Typography>
                    <Autocomplete
                      disablePortal
                      id="type-autocomplete"
                      options={progressionLevel}
                      className="custom-width"
                      getOptionLabel={(option) => option.value}
                      defaultValue={activityFormData?.progressionLevel || null}
                      onChange={(_, newValue) => {
                        handleActivityFormStateChange({
                          progressionLevel: newValue,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span> Skills & Concepts
                    </Typography>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={skillsConcepts}
                      className="custom-width"
                      getOptionLabel={(option: BaseResponse) => option.value}
                      defaultValue={activityFormData.skillsAndConcepts || []}
                      onChange={(_, newValue) => {
                        handleActivityFormStateChange({
                          skillsAndConcepts: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      classes={{
                        option: "autocomplete-option",
                        noOptions: "autocomplete-option",
                      }}
                      ChipProps={{
                        className: "autocomplete-chip",
                        variant: "filled",
                        deleteIcon: (
                          <CancelRoundedIcon className="autocomplete-delete-icon" />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Equipment
                    </Typography>
                    <Autocomplete
                      multiple
                      id="equipment-autocomplete"
                      options={equipment}
                      className="custom-width"
                      getOptionLabel={(option) => option.value}
                      value={activityFormData?.equipment || []}
                      onChange={(_, newValue) => {
                        handleActivityFormStateChange({ equipment: newValue });
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            fontFamily: "Montserrat",
                            fontSize: "12px ",
                          }}
                        >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={
                              selected ||
                              (activityFormData?.equipment?.some(
                                (e) => e._id === option._id,
                              ) ??
                                false)
                            }
                          />
                          {option.value}
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} />}
                      ChipProps={{
                        className: "autocomplete-chip",
                        variant: "filled",
                        deleteIcon: (
                          <CancelRoundedIcon className="autocomplete-delete-icon" />
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className="borderBottomStyles">
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Inclusion Guide Cross link
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      className="custom-width"
                      value={activityFormData.inclusionGuideCrosslink}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleTextFeildChange("inclusionGuideCrosslink", e);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                  >
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Has Spanish Translation
                    </Typography>
                    <FormControl
                      sx={EditSingleStyles.radioLabels}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup className="radioLabels">
                        <RadioGroup
                          aria-labelledby="has-spanish-translation-group"
                          name="has-spanish-translation-group"
                          value={activityFormData.hasSpanishTranslation}
                          onChange={(event) =>
                            handleAllRadioChange("hasSpanishTranslation", event)
                          }
                          row
                        >
                          <FormControlLabel
                            value="True"
                            control={<Radio />}
                            label="True"
                          />
                          <FormControlLabel
                            value="False"
                            control={<Radio />}
                            label="False"
                          />
                        </RadioGroup>
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={EditSingleStyles.gridAlign}
                  >
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      <span className="mandatory">*</span> Equipment Required?
                    </Typography>
                    <FormControl
                      sx={EditSingleStyles.radioLabels}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup className="radioLabels">
                        <RadioGroup
                          aria-labelledby="equipment-required-group"
                          name="equipment-required-group"
                          value={activityFormData.isEquipmentRequired}
                          onChange={(event) =>
                            handleAllRadioChange("isEquipmentRequired", event)
                          }
                          row
                        >
                          <FormControlLabel
                            value="True"
                            control={<Radio />}
                            label="True"
                          />
                          <FormControlLabel
                            value="False"
                            control={<Radio />}
                            label="False"
                          />
                        </RadioGroup>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3} className="gridContainer">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Video English
                    </Typography>
                    {activityFormData.videoEnglish ? (
                      <>
                        <div className="videoFrame">
                          <iframe
                            src={`https://player.vimeo.com/video/${activityFormData.videoEnglish}?badge=0&vimeo_logo=false`}
                            allow="autoplay; fullscreen; picture-in-picture"
                            title={
                              activityData &&
                              `${activityData.name} - CATCH Physical Activity Demo Video`
                            }
                          />
                          <ToggleButton
                            value="check"
                            color="primary"
                            selected={videoEnglishUploader}
                            onChange={toggleEnglishUploader}
                            style={{
                              margin: "0px 0px 10px 20px",
                            }}
                          >
                            {videoEnglishUploader ? (
                              <DeleteIcon sx={{ color: "black" }} />
                            ) : (
                              <UploadFileIcon sx={{ color: "#006fbf" }} />
                            )}
                          </ToggleButton>
                        </div>
                        {videoEnglishUploader && (
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            className="custom-width"
                            placeholder="Vimeo Id"
                            onChange={(event) =>
                              handleTextFeildChange(
                                "videoEnglish",
                                event as React.ChangeEvent<HTMLInputElement>,
                              )
                            }
                          />
                        )}
                      </>
                    ) : (
                      <TextField
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        className="custom-width"
                        placeholder="Vimeo Id"
                        onChange={(event) =>
                          handleTextFeildChange(
                            "videoEnglish",
                            event as React.ChangeEvent<HTMLInputElement>,
                          )
                        }
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Video Spanish
                    </Typography>
                    {activityFormData.videoSpanish ? (
                      <>
                        <div className="videoFrame">
                          <iframe
                            src={`https://player.vimeo.com/video/${activityFormData.videoSpanish}?badge=0&vimeo_logo=false`}
                            allow="autoplay; fullscreen; picture-in-picture"
                            title={
                              activityData &&
                              `${activityData.name} - CATCH Physical Activity Demo Video`
                            }
                          />
                          <ToggleButton
                            value="check"
                            color="primary"
                            selected={videoSpanishUploader}
                            onChange={toggleSpanishUploader}
                            style={{
                              margin: "0px 0px 10px 20px",
                            }}
                          >
                            {videoSpanishUploader ? (
                              <DeleteIcon sx={{ color: "black" }} />
                            ) : (
                              <UploadFileIcon sx={{ color: "#006fbf" }} />
                            )}
                          </ToggleButton>
                        </div>
                        {videoSpanishUploader && (
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            className="custom-width"
                            placeholder="Vimeo Id"
                            onChange={(event) =>
                              handleTextFeildChange(
                                "videoSpanish",
                                event as React.ChangeEvent<HTMLInputElement>,
                              )
                            }
                          />
                        )}
                      </>
                    ) : (
                      <TextField
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        className="custom-width"
                        placeholder="Vimeo Id"
                        onChange={(event) =>
                          handleTextFeildChange(
                            "videoSpanish",
                            event as React.ChangeEvent<HTMLInputElement>,
                          )
                        }
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      sx={EditSingleStyles.typoLabel}
                    >
                      Diagram
                    </Typography>
                    {!activityFormData?.diagram ||
                    activityFormData.diagram.length === 0 ? (
                      <SingleFileUploader onFileUpload={handleDiagramUpload} />
                    ) : (
                      activityFormData.diagram.map(
                        (item: unknown) =>
                          typeof item === "string" && (
                            <div key={item}>
                              <img
                                src={item}
                                alt="Organization's Logo"
                                className="orgImage"
                              />
                              <ToggleButton
                                value="check"
                                color="primary"
                                selected={imageUploader}
                                onChange={toggleImageUploader}
                                style={{ margin: "10px 0px 10px 20px" }}
                              >
                                {imageUploader ? (
                                  <DeleteIcon sx={{ color: "black" }} />
                                ) : (
                                  <UploadFileIcon sx={{ color: "#006fbf" }} />
                                )}
                              </ToggleButton>
                              {imageUploader && (
                                <SingleFileUploader
                                  onFileUpload={handleDiagramUpload}
                                />
                              )}
                            </div>
                          ),
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="gridContainer">
              <TabComponent
                value={tabValue}
                handleChange={handleTabChange}
                tabData={tabData[tabValue]}
                handleTabDataChange={handleTabDataChange}
                isEditing
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                className="centerAlign"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </>
        )}
      </Paper>
    </Box>
  );
}
