import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Button,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, Link } from "react-router-dom";
import ILocalStorageEnum from "../enums/LocalStorageEnum";
import HeaderComponentStyles from "./HeaderComponentStyles";
import "./HeaderComponent.scss";

const HeaderComponent = () => {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const logoutHandler = () => {
    localStorage.removeItem(ILocalStorageEnum.THINKIFIC_USER_DETAILS);
    navigate("/login");
  };

  return (
    <Grid sx={{ ...HeaderComponentStyles.grid }} className="HeaderComponent">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <List>
          <ListItem
            sx={{ ...HeaderComponentStyles.navListItem }}
            onClick={() => toggleDrawer(false)}
          >
            <Link className="nav_menuitem" to="/">
              Home
            </Link>
          </ListItem>
          <ListItem
            sx={{ ...HeaderComponentStyles.navListItem }}
            onClick={() => toggleDrawer(false)}
          >
            <Link className="nav_menuitem" to="/activities">
              Activities Grid
            </Link>
          </ListItem>
          <ListItem
            sx={{ ...HeaderComponentStyles.navListItem }}
            onClick={() => toggleDrawer(false)}
          >
            <Link className="nav_menuitem" to="/editMasterActivity">
              Master Data
            </Link>
          </ListItem>
          <ListItem
            sx={{ ...HeaderComponentStyles.navListItemButton }}
            onClick={() => toggleDrawer(false)}
          >
            <Button variant="contained" onClick={logoutHandler}>
              Logout
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </Grid>
  );
};

export default HeaderComponent;
