import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.customHeight": {
            marginBottom: "5px",
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "12px",
            fontFamily: "Montserrat, sans-serif",
          },
          "& .MuiInputBase-input": {
            fontSize: "14.4px",
            fontFamily: "Montserrat, sans-serif",
          },
        },
      },
      defaultProps: {
        InputLabelProps: {
          style: {
            fontFamily: "Montserrat, sans-serif",
          },
        },
        InputProps: {
          style: {
            fontFamily: "Montserrat, sans-serif",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&:focus": {
            borderColor: "#afaeb4",
          },
        },
        select: {
          fontSize: "0.8rem",
        },
      },
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 300,
              fontSize: "0.8rem",
              fontFamily: "Montserrat",
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        classes: {
          root: "greyLabel",
        },
        style: {
          fontFamily: "Montserrat",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#afaeb4",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.customHeight": {
            marginBottom: "5px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.customHeight": {
            marginBottom: "5px",
          },
        },
        inputRoot: {
          fontFamily: "Montserrat, sans-serif",
          "&.MuiAutocomplete-inputRoot": {
            fontSize: "0.8rem",
          },
          "&.Mui-focused": {
            borderColor: "#afaeb4",
          },
        },
        tag: {
          "& .MuiChip-root": {
            fontSize: "0.8rem",
          },
        },
        paper: {
          "& .MuiAutocomplete-listbox": {
            fontSize: "0.8rem",
            fontFamily: "Montserrat, sans-serif",
          },
          "& .MuiAutocomplete-noOptions": {
            fontSize: "0.8rem",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: "8px",
          paddingRight: "11px",
          fontFamily: "Montserrat",
        },
      },
    },
  },
});

const AdvanceSearchStyles = {
  modalContainer: {
    position: "absolute",
    top: "50px",
    left: "50%",
    transform: "translate(-50%, -0%)",
    bgcolor: "background.paper",
    p: 2,
    margin: "1px",
    borderRadius: "8px",
    color: "#36394D",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    width: {
      xs: "80%",
      sm: "70%",
      md: "60%",
      lg: "50%",
      xl: "40%",
    },
  },
  modalGridContainer: {
    height: "fit-content",
    padding: "30px",
    color: "#36394D",
  },
  modalHeader: { textAlign: "center", color: "#0063AB" },
  gridSpace: { pl: 2 },
  textFieldContainer: {
    color: "#36394D",
  },
  includescheckbox: { pl: 2 },
  checkboxgridstyle: {
    "& .MuiFormControlLabel-root": {
      marginRight: 0,
      marginLeft: 0,
      fontFamily: "Montserrat",
    },
    "& .checkbox-label": {
      fontSize: "0.9rem",
      marginLeft: "-8px",
      fontFamily: "Montserrat",
    },
  },
  gradeLabelStyle: { marginLeft: "-8px" },
  checkboxstyle: { transform: "scale(0.7)" },
  equipmentCheckbox: {
    transform: "scale(0.7 )",
    marginLeft: "10px",
  },
  equipmentStyle: {
    fontSize: "0.8rem",
    marginTop: "-2px",
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
      fontFamily: "Montserrat",
      margin: "0px 0px 3px -8px",
    },
  },
  savedActivityStyle: {
    fontSize: "0.8rem",
    marginTop: "-4px",
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
      fontFamily: "Montserrat",
      margin: "0px 0px 3px -8px",
    },
  },
  closeButtonStyle: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    color: "#adacb4",
  },
  checkboxLabel: {
    fontSize: "0.8rem",
    marginLeft: "0.8rem",
    color: "#9fa0a9 !important",
    fontFamily: "Montserrat",
  },
  searchBtnAlign: {
    textAlign: "center",
    paddingBottom: "25px",
  },
};

export default { ...AdvanceSearchStyles, customTheme };
