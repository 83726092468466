import { AxiosError } from "axios";

const errorHandling = (
  err: Error | AxiosError,
  errorAlert: (message: string) => void,
) => {
  if (err instanceof Error) {
    errorAlert(err.message);
  }
  if (err instanceof AxiosError) {
    errorAlert(
      (err?.response?.data as { message: string })?.message ||
        err?.response?.headers?.statusmessage ||
        "Something went wrong!",
    );
  }
};

export default errorHandling;
