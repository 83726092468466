import axios from "axios";
import ILocalStorageEnum from "../enums/LocalStorageEnum";

let accessToken = "";
let username = "";

const thinkificUserDetails =
  localStorage.getItem(ILocalStorageEnum.THINKIFIC_USER_DETAILS) || "";

try {
  const userDetails = JSON.parse(thinkificUserDetails);
  accessToken = userDetails.accessToken;
  username = userDetails.username;
} catch (error) {
  /* eslint-disable-next-line no-console */
  console.log(error);
}

const baseURL = process.env.REACT_APP_BASE_URL;
const BASE_API = axios.create({
  baseURL,
  headers: {
    Authorization: accessToken || "",
    username: username || "",
  },
});

export default BASE_API;
