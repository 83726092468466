import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";

interface RichTextEditorProps {
  value: string;
  tabData: string;
  handleTabDataChange: (newContent: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  tabData,
  handleTabDataChange,
}) => {
  const editor = useRef(null);
  const [editorContent, setEditorContent] = useState(value);

  useEffect(() => {
    setEditorContent(value);
  }, [value]);

  useEffect(() => {
    setEditorContent(tabData);
  }, [tabData]);

  const handleChange = (newContent: string): void => {
    setEditorContent(newContent);
    handleTabDataChange(newContent);
  };

  const editorConfig = {
    buttons: [
      "source",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "superscript",
      "subscript",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "table",
      "link",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "|",
      "fullsize",
    ],
    defaultFont: "Montserrat",
    style: {
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    iframeCSSLinks: [
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap",
    ],
    controls: {
      font: {
        defaultFont: "Montserrat",
        list: {
          Montserrat: "Montserrat",
        },
      },
    },
    clipboard: {
      insertOnlyText: true, // Example: Insert only text when pasting
    },
  };

  return (
    <JoditEditor
      ref={editor}
      value={editorContent}
      config={editorConfig}
      onBlur={(newContent) => handleChange(newContent)}
    />
  );
};

export default RichTextEditor;
