const EditMasterDataStyles = {
  gridContainer: {
    background: "#054672",
    textAlign: "center",
    color: "white",
    height: "70px",
    fontSize: "20px",
  },
  headerTypo: {
    marginTop: "1rem",
    fontFamily: "Montserrat",
  },
  verticalBorder: {
    borderRight: "1px solid #ccc",
  },
  listContent: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.7rem",
    marginRight: "auto",
  },
  tableContent: {
    padding: 2,
  },
  addBtn: {
    display: "flex",
    alignItems: "center",
  },
  tableLabel: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.9rem",
    marginRight: "auto",
  },
  tableRowFont: {
    backgroundColor: "#054672",
    color: "white",
  },
  tableRowStyle: {
    padding: "8px",
    backgroundColor: "#f0f0f0",
    fontFamily: "Montserrat, sans-serif",
  },
  toggleRow: {
    padding: "8px",
    color: "#f9ba1a",
    backgroundColor: "#f0f0f0",
    fontFamily: "Montserrat, sans-serif",
  },
  toggleFont: {
    fontSize: 32,
  },
  modalTypo: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "0.9rem",
    marginRight: "auto",
  },
};

export default EditMasterDataStyles;
