import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Paper,
  Autocomplete,
  ToggleButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import "../edit-single-activity/edit-single-activity.scss";
import TabComponent from "../richtext/TabComponent";
import SingleFileUploader from "../file-upload/single-file-upload ";
import EditSingleStyles from "../../../styles/editSingleActivity";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";
import { getAllMasterData } from "../../../APIs/masterDataApi";
import { createActivity } from "../../../APIs/activitiesApi";
import AlertContext from "../../../context/alertContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddSingleActivity() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [selectedSubTypes, setSelectedSubTypes] = useState<BaseResponse[]>([]);
  const [sampleValue, setSampleValue] = useState("No");
  const [types, setTypes] = useState<BaseResponse[]>([]);
  const [spanishValue, setSpanishValue] = React.useState("False");
  const [equipmentValue, setEquipmentValue] = React.useState("False");
  const [subType, setSubType] = useState<BaseResponse[]>([]);
  const [gradeBands, setGradeBands] = useState<BaseResponse[]>([]);
  const [noOfPlayers, setNoOfPlayers] = useState<BaseResponse[]>([]);
  const [spaceRequirement, setSpaceRequirement] = useState<BaseResponse[]>([]);
  const [progressionLevel, setProgressionLevel] = useState<BaseResponse[]>([]);
  const [skillsConcepts, setSkillsConcepts] = useState<BaseResponse[]>([]);
  const [selectedSkillsConcepts, setSelectedSkillsConcepts] = useState<
    BaseResponse[]
  >([]);
  const [standards, setStandards] = useState<BaseResponse[]>([]);
  const [selCompetencies, setSelCompetencies] = useState<BaseResponse[]>([]);
  const [equipment, setEquipment] = useState<BaseResponse[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<BaseResponse | null>(null);
  const [selectedNoOfPlayers, setSelectedNoOfPlayers] =
    useState<BaseResponse | null>(null);
  const [selectedSpaceRequirements, setSelectedSpaceRequirements] =
    useState<BaseResponse | null>(null);
  const [selectedProgressionLevel, setSelectedProgressionLevel] =
    useState<BaseResponse | null>(null);
  const [activityData, setActivityData] = useState<IActivity | null>(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [subStandardsAlignment, setSubStandardsAlignment] =
    useState<string>("");
  const { successAlert, errorAlert } = useContext<IAlertContext>(AlertContext);
  // const { setIsLoader } = useContext<ILoader>(LoaderContext);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSampleValue(event.target.value);
  };
  const handleSpanishRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSpanishValue(event.target.value);
  };

  const handleEquipmentRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEquipmentValue(event.target.value);
  };

  const [videoEnglishUploader, setVideoEnglishUploader] = useState(false);
  const [videoSpanishUploader, setVideoSpanishUploader] = useState(false);
  const [imageUploader, setImageUploader] = useState(false);
  const [videoEnglish, setVideoEnglish] = useState("");
  const [videoSpanish, setVideoSpanish] = useState("");
  const [uploadedDiagrams, setUploadedDiagrams] = useState<File[]>([]);
  const [tabData, setTabData] = React.useState<string[]>([]);
  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const data = await getAllMasterData(errorAlert);
        if (data) {
          const dataByType = (type: string) =>
            data.filter((item) => item.type === type);
          setTypes(dataByType("type"));
          setSubType(dataByType("subType"));
          setGradeBands(dataByType("gradeBands"));
          setNoOfPlayers(dataByType("numberOfPlayers"));
          setSpaceRequirement(dataByType("spaceRequirement"));
          setProgressionLevel(dataByType("progressionLevel"));
          setSkillsConcepts(dataByType("skillsAndConcepts"));
          setStandards(dataByType("standardsAlignments"));
          setSelCompetencies(dataByType("selCompetencies"));
          setEquipment(dataByType("equipment"));
        }
      } catch (error) {
        errorAlert("Error fetching master data");
      }
    };

    fetchMasterData();
  }, [errorAlert]);

  const toggleEnglishUploader = () => {
    setVideoEnglishUploader((prevShowUploader) => !prevShowUploader);
  };
  const toggleSpanishUploader = () => {
    setVideoSpanishUploader((prevShowUploader) => !prevShowUploader);
  };
  const toggleImageUploader = () => {
    setImageUploader((prevShowUploader) => !prevShowUploader);
  };

  const handleTabDataChange = (newContent: string) => {
    const updatedTabData = [...tabData];
    updatedTabData[tabValue] = newContent;
    setTabData(updatedTabData);
  };
  //   const validateForm = (): string[] => {
  //     const errors: string[] = [];
  //     if (!name) errors.push("Activity name is required.");
  //     if (!activityData?.type) errors.push("Activity type is required.");
  //     if (!activityData?.gradeBands?.length)
  //       errors.push("At least one grade band is required.");
  //     if (!activityData?.numberOfPlayers)
  //       errors.push("No. of Players  is required.");
  //     if (!activityData?.spaceRequirement)
  //       errors.push("Space Requirement  is required.");
  //     if (!activityData?.standardsAlignments?.length)
  //       errors.push("At least one alignment is required.");
  //     if (!activityData?.progressionLevel)
  //       errors.push("Progression Level is required.");
  //     if (!activityData?.skillsAndConcepts)
  //       errors.push("Skills and concepts  is required.");
  //     if (!activityData?.equipment) errors.push("isEquipment is required.");
  //     return errors;
  //   };
  const handleSubmit = async () => {
    // const errors = validateForm();
    // if (errors.length > 0) {
    //   errors.forEach((error) => errorAlert(error));
    //   return;
    // }

    if (!activityData) return;

    try {
      const updatedData: Partial<IActivity> = {
        ...activityData,
        name,
        subType: selectedSubTypes[0],
        isFreeSample: sampleValue === "Yes",
        type: selectedTypes || activityData.type,
        gradeBands: activityData.gradeBands,
        standardsAlignments: activityData.standardsAlignments,
        selCompetencies: activityData.selCompetencies,
        numberOfPlayers: selectedNoOfPlayers || activityData.numberOfPlayers,
        spaceRequirement:
          selectedSpaceRequirements || activityData.spaceRequirement,
        progressionLevel:
          selectedProgressionLevel || activityData.progressionLevel,
        skillsAndConcepts: selectedSkillsConcepts,
        subStandardsAlignments: subStandardsAlignment
          .split(", ")
          .map((value) => value.trim()),
        videoEnglish: videoEnglish || activityData.videoEnglish,
        videoSpanish: videoSpanish || activityData.videoSpanish,
        diagram:
          uploadedDiagrams.length > 0
            ? uploadedDiagrams.map((file) => URL.createObjectURL(file))
            : activityData.diagram,
        hasSpanishTranslation: spanishValue === "True",
        isEquipmentRequired: equipmentValue === "True",
        equipmentDescription: tabData[0],
        organization: tabData[1],
        instructions: tabData[2],
        teachingSuggestions: tabData[3],
        variations: tabData[4],
        differentiation: tabData[5],
        selReinforcement: tabData[6],
        inclusionAdaptation: tabData[7],
        virtualAdaption: tabData[8],
      };
      const updatedDataAPI: {
        [key: string]: string | number | boolean | string[];
      } = {};

      Object.entries(updatedData).forEach(([key, value]) => {
        if (
          [
            "type",
            "subType",
            "numberOfPlayers",
            "spaceRequirement",
            "progressionLevel",
            "videoEnglish",
            "videoSpanish",
            "inclusionGuideCrosslink",
            "organization",
            "equipmentDescription",
            "instructions",
            "teachingSuggestions",
            "variations",
            "differentiation",
            "selReinforcement",
            "inclusionAdaptation",
            "virtualAdaption",
          ].includes(key)
        ) {
          updatedDataAPI[key] =
            value && typeof value === "object" && "_id" in value
              ? (value as BaseResponse)._id
              : (value as string | number | boolean);
        } else if (
          [
            "gradeBands",
            "standardsAlignments",
            "subStandardsAlignments",
            "selCompetencies",
            "skillsAndConcepts",
            "diagram",
            "equipment",
          ].includes(key)
        ) {
          updatedDataAPI[key] = (value as BaseResponse[]).map((item) =>
            typeof item === "object" && "_id" in item
              ? item._id
              : (item as unknown as string),
          );
        } else {
          updatedDataAPI[key] = value as string | string[] | number | boolean;
        }
      });

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ...updatedDataAPI,
          isFreeSample: updatedDataAPI.isFreeSample ? "true" : "false",
          hasSpanishTranslation: updatedDataAPI.hasSpanishTranslation
            ? "true"
            : "false",
          isEquipmentRequired: updatedDataAPI.isEquipmentRequired
            ? "true"
            : "false",
        }),
      );

      uploadedDiagrams.forEach((item, index) => {
        formData.append(`diagram[${index}]`, item);
      });

      const updatedActivity = await createActivity(errorAlert, formData);
      if (updatedActivity) {
        setActivityData(updatedActivity);
        successAlert("Activity updated successfully");
      }
    } catch (error) {
      errorAlert("Error updating activity");
    }
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleVideoEnglishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoEnglish(e.target.value);
  };
  const handleVideoSpanishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoSpanish(e.target.value);
  };

  const handleDiagramUpload = (files: File[]) => {
    setUploadedDiagrams(files);
  };

  return (
    <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={EditSingleStyles.typoLabel}>
          Add Activity
        </Typography>
      </Grid>
      <Paper sx={{ padding: 2 }}>
        {/* {activityData && ( */}
        <>
          <Grid container>
            <Grid item xs={12} sm={6} md={9} className="gridContainer">
              <Grid container className="borderBottomStyle">
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span>Name
                  </Typography>
                  <TextField
                    required
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    className="custom-width"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {/* <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    className="custom-width"
                    value={activityData?.name || ""}
                    onChange={(e) =>
                      setActivityData((prevData) =>
                        prevData
                          ? { ...prevData, name: e.target.value }
                          : prevData,
                      )
                    }
                  /> */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                  className="checkbox"
                >
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Free Sample
                  </Typography>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup className="radioLabels">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={sampleValue}
                        onChange={handleRadioChange}
                        row
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Type
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={types}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    onChange={(event, newValue) => {
                      setSelectedTypes(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Sub-Type
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={subType}
                    className="custom-width"
                    value={selectedSubTypes}
                    getOptionLabel={(option: BaseResponse) => option.value}
                    // defaultValue={activityData.subType || []}
                    onChange={(event, newValue) => {
                      setSelectedSubTypes(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    classes={{
                      option: "autocomplete-option",
                      noOptions: "autocomplete-option",
                    }}
                    ChipProps={{
                      className: "autocomplete-chip",
                      variant: "filled",
                      deleteIcon: (
                        <CancelRoundedIcon className="autocomplete-delete-icon" />
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                  className="checkbox"
                >
                  <Typography
                    variant="subtitle2"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Grade Bands
                  </Typography>

                  <FormControl
                    sx={EditSingleStyles.checkboxLabelMargin}
                    component="fieldset"
                    variant="standard"
                    size="small"
                  >
                    <FormGroup row>
                      {gradeBands.map((gradeBand) => (
                        <FormControlLabel
                          key={gradeBand._id}
                          control={
                            <Checkbox
                              name={gradeBand.value}
                              size="small"
                              checked={
                                activityData?.gradeBands
                                  ?.map((gb) => gb._id)
                                  .includes(gradeBand._id) || false
                              }
                              onChange={(e) => {
                                const newGradeBands = e.target.checked
                                  ? [
                                      ...(activityData?.gradeBands || []),
                                      gradeBand,
                                    ]
                                  : (activityData?.gradeBands || []).filter(
                                      (band) => band._id !== gradeBand._id,
                                    );
                                setActivityData((prevData) =>
                                  prevData
                                    ? {
                                        ...prevData,
                                        gradeBands: newGradeBands,
                                      }
                                    : prevData,
                                );
                              }}
                            />
                          }
                          label={
                            <span className="formLabel">{gradeBand.value}</span>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container className="borderBottomStyle">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                >
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> No.of players
                  </Typography>

                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={noOfPlayers}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    // defaultValue={activityData?.numberOfPlayers || null}
                    onChange={(event, newValue) => {
                      setSelectedNoOfPlayers(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                >
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Space Requirements
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={spaceRequirement}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    // defaultValue={activityData?.spaceRequirement || null}
                    onChange={(event, newValue) => {
                      setSelectedSpaceRequirements(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
              <Grid container className="borderBottomStyle">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                  className="checkbox"
                >
                  <Typography
                    variant="subtitle2"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Standards Main Alignment
                  </Typography>

                  <FormControl
                    sx={EditSingleStyles.radioLabels}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup row>
                      {standards.map((standard) => (
                        <FormControlLabel
                          key={standard._id}
                          control={
                            <Checkbox
                              name={standard.value}
                              size="small"
                              checked={
                                activityData?.standardsAlignments?.some(
                                  (alignment) =>
                                    alignment.value === standard.value,
                                ) || false
                              }
                              onChange={(e) => {
                                const newStandardsAlignments = e.target.checked
                                  ? [
                                      ...(activityData?.standardsAlignments ||
                                        []),
                                      standard,
                                    ]
                                  : (
                                      activityData?.standardsAlignments || []
                                    ).filter(
                                      (alignment) =>
                                        alignment.value !== standard.value,
                                    );
                                setActivityData((prevData) =>
                                  prevData
                                    ? {
                                        ...prevData,
                                        standardsAlignments:
                                          newStandardsAlignments,
                                      }
                                    : prevData,
                                );
                              }}
                            />
                          }
                          label={
                            <span className="formLabel">{standard.value}</span>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                >
                  <Typography
                    variant="subtitle2"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Sub-Standards Main Alignment
                  </Typography>

                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    className="custom-width"
                    value={subStandardsAlignment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      setSubStandardsAlignment(value);
                    }}
                    placeholder="Enter multiple values with separated by comma"
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="checkbox">
                  <Typography
                    variant="subtitle2"
                    ml={1}
                    sx={EditSingleStyles.typoLabel}
                  >
                    SEL Competencies
                  </Typography>
                  <FormControl
                    sx={EditSingleStyles.radioLabels}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup row>
                      {selCompetencies.map((selCompetency) => (
                        <FormControlLabel
                          key={selCompetency._id}
                          control={
                            <Checkbox
                              name={selCompetency.value}
                              size="small"
                              checked={
                                activityData?.selCompetencies?.some(
                                  (competency) =>
                                    competency.value === selCompetency.value,
                                ) || false
                              }
                              onChange={(e) => {
                                const newSelCompetencies = e.target.checked
                                  ? [
                                      ...(activityData?.selCompetencies || []),
                                      selCompetency,
                                    ]
                                  : (
                                      activityData?.selCompetencies || []
                                    ).filter(
                                      (alignment) =>
                                        alignment.value !== selCompetency.value,
                                    );
                                setActivityData((prevData) =>
                                  prevData
                                    ? {
                                        ...prevData,
                                        selCompetencies: newSelCompetencies,
                                      }
                                    : prevData,
                                );
                              }}
                            />
                          }
                          label={
                            <span className="formLabel">
                              {selCompetency.value}
                            </span>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className="borderBottomStyle">
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Progression level
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={progressionLevel}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    // defaultValue={activityData?.progressionLevel || null}
                    onChange={(event, newValue) => {
                      setSelectedProgressionLevel(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Skills & Concepts
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={skillsConcepts}
                    className="custom-width"
                    getOptionLabel={(option: BaseResponse) => option.value}
                    // defaultValue={activityData.skillsAndConcepts || []}
                    onChange={(event, newValue) => {
                      setSelectedSkillsConcepts(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    classes={{
                      option: "autocomplete-option",
                      noOptions: "autocomplete-option",
                    }}
                    ChipProps={{
                      className: "autocomplete-chip",
                      variant: "filled",
                      deleteIcon: (
                        <CancelRoundedIcon className="autocomplete-delete-icon" />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Equipment
                  </Typography>
                  <Autocomplete
                    multiple
                    id="equipment-autocomplete"
                    options={equipment}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    value={activityData?.equipment || []}
                    onChange={(event, newValue) => {
                      setActivityData((prevData) => {
                        if (!prevData) return prevData;
                        return {
                          ...prevData,
                          equipment: newValue,
                        };
                      });
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "12px ",
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={
                            selected ||
                            (activityData?.equipment?.some(
                              (e) => e._id === option._id,
                            ) ??
                              false)
                          }
                        />
                        {option.value}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} />}
                    ChipProps={{
                      className: "autocomplete-chip",
                      variant: "filled",
                      deleteIcon: (
                        <CancelRoundedIcon className="autocomplete-delete-icon" />
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className="borderBottomStyles">
                <Grid item xs={12} sm={6} md={4}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Inclusion Guide Cross link
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    className="custom-width"
                    value={activityData?.inclusionGuideCrosslink || ""}
                    onChange={(e) =>
                      setActivityData((prevData) =>
                        prevData
                          ? {
                              ...prevData,
                              inclusionGuideCrosslink: e.target.value,
                            }
                          : prevData,
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                >
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Has Spanish Translation
                  </Typography>
                  <FormControl
                    sx={EditSingleStyles.radioLabels}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup className="radioLabels">
                      <RadioGroup
                        aria-labelledby="has-spanish-translation-group"
                        name="has-spanish-translation-group"
                        value={spanishValue}
                        onChange={handleSpanishRadioChange}
                        row
                      >
                        <FormControlLabel
                          value="True"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="False"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  sx={EditSingleStyles.gridAlign}
                >
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    <span className="mandatory">*</span> Equipment Required?
                  </Typography>
                  <FormControl
                    sx={EditSingleStyles.radioLabels}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup className="radioLabels">
                      <RadioGroup
                        aria-labelledby="equipment-required-group"
                        name="equipment-required-group"
                        value={equipmentValue}
                        onChange={handleEquipmentRadioChange}
                        row
                      >
                        <FormControlLabel
                          value="True"
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value="False"
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="gridContainer">
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Video English
                  </Typography>
                  {activityData?.videoEnglish ? (
                    <>
                      <div className="videoFrame">
                        <iframe
                          src={`https://player.vimeo.com/video/${activityData.videoEnglish}?badge=0&vimeo_logo=false`}
                          allow="autoplay; fullscreen; picture-in-picture"
                          title={
                            activityData &&
                            `${activityData.name} - CATCH Physical Activity Demo Video`
                          }
                        />
                        <ToggleButton
                          value="check"
                          color="primary"
                          selected={videoEnglishUploader}
                          onChange={toggleEnglishUploader}
                          style={{
                            margin: "0px 0px 10px 20px",
                          }}
                        >
                          {videoEnglishUploader ? (
                            <DeleteIcon sx={{ color: "black" }} />
                          ) : (
                            <UploadFileIcon sx={{ color: "#006fbf" }} />
                          )}
                        </ToggleButton>
                      </div>
                      {videoEnglishUploader && (
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          className="custom-width"
                          placeholder="Vimeo Id"
                          onChange={handleVideoEnglishChange}
                        />
                      )}
                    </>
                  ) : (
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      className="custom-width"
                      placeholder="Vimeo Id"
                      onChange={handleVideoEnglishChange}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Video Spanish
                  </Typography>
                  {activityData?.videoSpanish ? (
                    <>
                      <div className="videoFrame">
                        <iframe
                          src={`https://player.vimeo.com/video/${activityData.videoSpanish}?badge=0&vimeo_logo=false`}
                          allow="autoplay; fullscreen; picture-in-picture"
                          title={
                            activityData &&
                            `${activityData.name} - CATCH Physical Activity Demo Video`
                          }
                        />
                        <ToggleButton
                          value="check"
                          color="primary"
                          selected={videoSpanishUploader}
                          onChange={toggleSpanishUploader}
                          style={{
                            margin: "0px 0px 10px 20px",
                          }}
                        >
                          {videoSpanishUploader ? (
                            <DeleteIcon sx={{ color: "black" }} />
                          ) : (
                            <UploadFileIcon sx={{ color: "#006fbf" }} />
                          )}
                        </ToggleButton>
                      </div>
                      {videoSpanishUploader && (
                        <TextField
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          className="custom-width"
                          placeholder="Vimeo Id"
                          onChange={handleVideoSpanishChange}
                        />
                      )}
                    </>
                  ) : (
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      className="custom-width"
                      placeholder="Vimeo Id"
                      onChange={handleVideoSpanishChange}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={EditSingleStyles.typoLabel}
                  >
                    Diagram
                  </Typography>
                  {!activityData?.diagram ||
                  activityData.diagram.length === 0 ? (
                    <SingleFileUploader onFileUpload={handleDiagramUpload} />
                  ) : (
                    activityData.diagram.map((item: string) => (
                      <div key={item}>
                        <img
                          src={item}
                          alt="Organization's Logo"
                          className="orgImage"
                        />
                        <ToggleButton
                          value="check"
                          color="primary"
                          selected={imageUploader}
                          onChange={toggleImageUploader}
                          style={{ margin: "10px 0px 10px 20px" }}
                        >
                          {imageUploader ? (
                            <DeleteIcon sx={{ color: "black" }} />
                          ) : (
                            <UploadFileIcon sx={{ color: "#006fbf" }} />
                          )}
                        </ToggleButton>
                        {imageUploader && (
                          <SingleFileUploader
                            onFileUpload={handleDiagramUpload}
                          />
                        )}
                      </div>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridContainer">
            <TabComponent
              value={tabValue}
              handleChange={handleTabChange}
              tabData={tabData[tabValue]}
              handleTabDataChange={handleTabDataChange}
              isEditing={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="contained"
              className="centerAlign"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </>
        {/* )} */}
      </Paper>
    </Box>
  );
}
