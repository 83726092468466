const advancedSearchForm: IAdvancedSearchFormType = {
  name: {
    name: "name",
    label: "Activity Name",
    value: "",
    isValid: true,
    errorMessage: "",
  },
  type: {
    value: null,
    options: [],
  },
  subType: {
    value: [],
    options: [],
    initialList: [],
  },
  gradeBands: {
    name: "gradeBands",
    value: [],
    options: [],
  },
  skillsAndConcepts: {
    value: [],
    options: [],
  },
  numberOfPlayers: {
    value: null,
    options: [],
  },
  spaceRequirement: {
    value: null,
    options: [],
  },
  equipment: {
    value: [],
    options: [],
  },
  isEquipmentRequired: {
    value: false,
  },
  isSearchInSavedActivities: { value: false },
  progressionLevel: {
    value: null,
    options: [],
  },
  shapePEStandards: {
    value: "",
    name: "shapePEStandards",
  },
};

export default advancedSearchForm;
