const removeQuotes = (str: string) => str.replace(/['"]/g, "");

const sortActivities = (data: IActivity[]) => {
  return data
    ?.sort((a, b) => {
      const nameA = removeQuotes(a.name).toLowerCase();
      const nameB = removeQuotes(b.name).toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    ?.sort((a, b) => Number(b.isRelatedToUser) - Number(a.isRelatedToUser));
};

export default sortActivities;
