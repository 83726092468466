const areNotAllValuesNullOrEmpty = (object: IAdvancedSearchFormType) => {
  return Object.values(object).some((p) => {
    if (
      p.value === false ||
      p.value === "" ||
      p.value === null ||
      (Array.isArray(p.value) && p.value.length === 0)
    ) {
      return false;
    }
    return true;
  });
};

export default areNotAllValuesNullOrEmpty;
