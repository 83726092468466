const activityFormInitialData: IActivityData = {
  name: {
    isRequired: true,
    value: "",
    key: "name",
    title: "Name",
    isValid: true,
    validationErrorMsg:
      "Activity Name is required and cannot be complete numbers",
  },
  isFreeSample: {
    isRequired: false,
    value: true,
    key: "isFreeSample",
    title: "Free Sample",
    isValid: true,
  },
  type: {
    isRequired: true,
    value: null,
    key: "type",
    title: "Type",
    options: [],
    isValid: true,
    validationErrorMsg: "Activity Type is required",
  },
  subType: {
    isRequired: false,
    value: null,
    options: [],
    displayOptions: [],
    key: "subType",
    title: "Sub-Type",
    isValid: true,
    validationErrorMsg: "Please enter a valid sub-type from the dropdown",
  },
  gradeBands: {
    isRequired: true,
    value: [],
    key: "gradeBands",
    title: "Grade Bands",
    options: [],
    isValid: true,
    validationErrorMsg: "At least one grade band is required.",
  },
  numberOfPlayers: {
    isRequired: true,
    value: null,
    key: "numberOfPlayers",
    title: "No.of Players",
    options: [],
    isValid: true,
    validationErrorMsg: "No. of Players  is required.",
  },
  spaceRequirement: {
    isRequired: true,
    value: null,
    key: "spaceRequirement",
    title: "Space Requirements",
    options: [],
    isValid: true,
    validationErrorMsg: "Space Requirement  is required.",
  },
  standardsAlignments: {
    isRequired: false,
    value: [],
    key: "standardsAlignments",
    title: "Standards Main Alignment",
    options: [],
    isValid: true,
  },
  subStandardsAlignments: {
    isRequired: false,
    value: "",
    key: "subStandardsAlignments",
    title: "Sub-Standards Main Alignment",
    isValid: true,
    validationErrorMsg: "Please enter a valid sub standard alignment",
  },
  selCompetencies: {
    isRequired: false,
    value: [],
    key: "selCompetencies",
    title: "SEL Competencies",
    options: [],
    isValid: true,
  },
  progressionLevel: {
    isRequired: false,
    value: null,
    key: "progressionLevel",
    title: "Progression level",
    options: [],
    isValid: true,
    validationErrorMsg:
      "Please enter a valid Progression level from the dropdown",
  },
  skillsAndConcepts: {
    isRequired: true,
    value: [],
    key: "skillsAndConcepts",
    title: "Skills & Concepts",
    options: [],
    isValid: true,
    validationErrorMsg: "Skills and concepts  is required.",
  },
  equipment: {
    isRequired: false,
    value: [],
    key: "equipment",
    title: "Equipment",
    options: [],
    isValid: true,
  },
  inclusionGuideCrosslink: {
    isRequired: false,
    value: "",
    key: "inclusionGuideCrosslink",
    title: "Inclusion Guide Cross link",
    isValid: true,
    validationErrorMsg: "Invalid spaces inside the link",
  },
  hasSpanishTranslation: {
    isRequired: false,
    value: false,
    key: "hasSpanishTranslation",
    title: "Has Spanish Translation",
    isValid: true,
  },
  isEquipmentRequired: {
    isRequired: true,
    value: false,
    key: "isEquipmentRequired",
    title: "Equipment Required?",
    isValid: true,
  },
  videoEnglish: {
    isRequired: false,
    value: "",
    key: "videoEnglish",
    title: "Video English",
    isUploaderActive: false,
    isValid: true,
    validationErrorMsg: "Vimeo id will only be numbers",
  },
  videoSpanish: {
    isRequired: false,
    value: "",
    key: "videoSpanish",
    title: "Video Spanish",
    isUploaderActive: false,
    isValid: true,
    validationErrorMsg: "Vimeo id will only be numbers",
  },
  diagram: {
    isRequired: false,
    value: [],
    key: "diagram",
    title: "Diagrams",
    uploadedData: [],
    isUploaderActive: false,
    isValid: true,
  },
  equipmentDescription: {
    isRequired: false,
    value: "",
    key: "equipmentDescription",
    title: "Equipment Description",
    tabId: 0,
    isValid: true,
  },
  organization: {
    isRequired: true,
    value: "",
    key: "organization",
    title: "Organization",
    tabId: 1,
    isValid: true,
    validationErrorMsg: "Organization field is required.",
  },
  instructions: {
    isRequired: true,
    value: "",
    key: "instructions",
    title: "instructions",
    tabId: 2,
    isValid: true,
    validationErrorMsg: "Instructions are required.",
  },
  teachingSuggestions: {
    isRequired: false,
    value: "",
    key: "teachingSuggestions",
    title: "Teaching Suggestion",
    tabId: 3,
    isValid: true,
  },
  variations: {
    isRequired: false,
    value: "",
    key: "variations",
    title: "Variations",
    tabId: 4,
    isValid: true,
  },
  differentiation: {
    isRequired: false,
    value: "",
    key: "differentiation",
    title: "Differentiations",
    tabId: 5,
    isValid: true,
  },
  selReinforcement: {
    isRequired: false,
    value: "",
    key: "selReinforcement",
    title: "Sel Reinforcement",
    tabId: 6,
    isValid: true,
  },
  inclusionAdaptation: {
    isRequired: false,
    value: "",
    key: "inclusionAdaptation",
    title: "Inclusion Adaptation",
    tabId: 7,
    isValid: true,
  },
  virtualAdaption: {
    isRequired: false,
    value: "",
    key: "virtualAdaption",
    title: "Virtual Adaptation",
    tabId: 8,
    isValid: true,
  },
  tabData: {
    tabId: 0,
  },
};

export default activityFormInitialData;
