const AlertServiceStyles = {
  alert: {
    backgroundColor: "#28a745",
    padding: "3px 10px",
    color: "white",
    borderRadius: "3px",
  },
};

export default AlertServiceStyles;
