import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Paper,
  Autocomplete,
  ToggleButton,
  FormHelperText,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import "./create-edit-single-activity.scss";
import TabComponent from "../richtext/TabComponent";
import SingleFileUploader from "../file-upload/single-file-upload ";
import EditSingleStyles from "../../../styles/editSingleActivity";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";
import { fetchActivityData } from "../../../APIs/viewSingleActivityApi";
import { getAllMasterData } from "../../../APIs/masterDataApi";
import { updateActivity, createActivity } from "../../../APIs/activitiesApi";
import AlertContext from "../../../context/alertContext";
import LoaderContext from "../../../context/loaderContext";
import activityFormInitialData from "./create-edit-single-activity-form";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FieldLabel = ({
  title,
  isRequired = false,
}: {
  title: string;
  isRequired: boolean;
}) => (
  <>
    {isRequired && <span className="mandatory">* </span>}
    {title}
  </>
);

export default function SingleActivityForm() {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [activityFormData, setActivityFormSetData] = useState<IActivityData>({
    ...activityFormInitialData,
  });

  const [activityData, setActivityData] = useState<IActivity | null>(null);

  const { successAlert, errorAlert } = useContext<IAlertContext>(AlertContext);
  const { setIsLoader } = useContext<ILoader>(LoaderContext);

  const handleFormStateChange = useCallback(
    (
      key: keyof typeof masterDataKeys | string,
      updatedFields: Partial<
        | IFormField
        | IFormFieldBaseResponse
        | IFormFieldBaseResponseArray
        | IFormFieldBoolean
        | IFormFieldSubType
        | IFormFileUploadMedia
        | IFormMedia
        | IFormOptions
        | IFormTabField
      >,
    ) => {
      setActivityFormSetData((prev) => ({
        ...prev,
        [key]: {
          ...prev[key as keyof typeof prev],
          ...updatedFields,
        },
      }));
    },
    [],
  );

  const handleValueChange = (
    key: string,
    data: boolean | string | string[] | BaseResponse | BaseResponse[] | null,
  ) => {
    handleFormStateChange(key, {
      value: data,
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    handleFormStateChange("tabData", { tabId: newValue });
  };

  const handleUploaderChange = (field: string, currentVal: boolean) => {
    handleFormStateChange(field, {
      isUploaderActive: !currentVal,
    });
  };

  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);

  const masterDataKeys = {
    type: "type",
    subType: "subType",
    gradeBands: "gradeBands",
    numberOfPlayers: "numberOfPlayers",
    spaceRequirement: "spaceRequirement",
    progressionLevel: "progressionLevel",
    skillsAndConcepts: "skillsAndConcepts",
    standardsAlignments: "standardsAlignments",
    selCompetencies: "selCompetencies",
    equipment: "equipment",
  };

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const masterData = await getAllMasterData(errorAlert);
        if (masterData) {
          Object.keys(masterDataKeys).forEach((key) => {
            const type = masterDataKeys[key as keyof typeof masterDataKeys];
            const filteredData = masterData.filter(
              (item) => item.type === type,
            );
            handleFormStateChange(key as keyof typeof masterDataKeys, {
              ...(key === "subType" ? { displayOptions: filteredData } : {}),
              options: filteredData,
            });
          });
        }
      } catch (error) {
        errorAlert("Error fetching master data");
      }
    };

    const fetchActivityDataById = async () => {
      setIsLoader(true);
      try {
        if (!id) {
          setActivityData(null);
          setIsLoader(false);
          return;
        }
        const activityDetails = await fetchActivityData(id);
        setActivityData(activityDetails);

        Object.keys(activityFormData).forEach((key) => {
          const filteredData =
            activityDetails[key as keyof typeof activityData];
          if (key === "subStandardsAlignments") {
            handleValueChange(key, [...filteredData].join(", "));
          } else if (key === "videoEnglish" || key === "videoSpanish") {
            handleValueChange(key, filteredData);
            handleUploaderChange(key, Boolean(!filteredData));
          } else {
            handleValueChange(key, filteredData);
          }
        });
      } catch (error) {
        errorAlert("Error fetching activity data");
      }
      setIsLoader(false);
    };

    fetchMasterData();
    fetchActivityDataById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAlert, id, setIsLoader]);

  useEffect(() => {
    const handelSubTypesOptionsAndValue = (
      currentType: BaseResponse | null,
    ) => {
      const { subType } = activityFormData;

      const currentSubTypeOptions: BaseResponse[] = currentType
        ? subType.options.filter(
            (item) => item.reference?.includes(currentType?._id),
          )
        : subType.options;

      // this is to not remove the subType on all typeField changes
      const newSubTypeValue = subType.value?.reference?.includes(
        currentType?._id ?? "",
      )
        ? subType.value
        : null;

      handleFormStateChange("subType", {
        displayOptions: currentSubTypeOptions,
        value: newSubTypeValue,
      });
    };

    handelSubTypesOptionsAndValue(activityFormData.type.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityFormData.type.value]);

  const currentTab = Object.values(activityFormData).find(
    (item) => item.tabId === activityFormData.tabData.tabId,
  );

  const handleTabDataChange = (newContent: string) => {
    handleValueChange(currentTab.key, newContent);
  };

  const handleDiagramUpload = (files: File[]) => {
    handleFormStateChange("diagram", {
      uploadedData: files,
    });
  };

  const handleAllRadioChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const result = value === "Yes" || value === "True";

    if (
      field === "isFreeSample" ||
      field === "hasSpanishTranslation" ||
      field === "isEquipmentRequired"
    )
      handleValueChange(field, result);
  };

  const handleAllCheckboxChange = (field: string, item: BaseResponse) => {
    const getUpdatedSelections = (items: BaseResponse[]): BaseResponse[] => {
      return items.some((selected) => selected._id === item._id)
        ? items.filter((selected) => selected._id !== item._id)
        : [...items, item];
    };

    if (field === "gradeBands")
      handleValueChange(
        "gradeBands",
        getUpdatedSelections(activityFormData.gradeBands.value),
      );
    if (field === "standardsAlignments")
      handleValueChange(
        "standardsAlignments",
        getUpdatedSelections(activityFormData.standardsAlignments.value),
      );

    if (field === "selCompetencies")
      handleValueChange(
        "selCompetencies",
        getUpdatedSelections(activityFormData.selCompetencies.value),
      );
  };

  const handleTextFieldChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const trimmedValue = value.trim();

    if (field === "name" || field === "inclusionGuideCrosslink")
      handleValueChange(field, trimmedValue);

    if (
      field === "videoEnglish" ||
      field === "videoSpanish" ||
      field === "subStandardsAlignments"
    )
      handleValueChange(field, value);
  };

  const handleValidationStateChange = (field: string, newVal: boolean) => {
    handleFormStateChange(field, {
      isValid: newVal,
    });
  };

  const validateForm = (): string[] => {
    const hasErrors: string[] = [];

    Object.values(activityFormData).forEach((item) => {
      const { key, value } = item;

      const nameRegex = /^(?!\d+$).+/;
      const inclusionGCLinkRegex = /^\S+$/;
      const videoInputRegex = /^\d+$/;

      switch (key) {
        case "name": {
          const isValid = nameRegex.test(value) && value;
          handleValidationStateChange(key, isValid);
          if (!isValid) hasErrors.push(key);
          break;
        }
        case "inclusionGuideCrosslink": {
          if (value) {
            const isValid = inclusionGCLinkRegex.test(value);
            handleValidationStateChange(key, isValid);
            if (!isValid) hasErrors.push(key);
          }
          break;
        }
        case "type":
        case "numberOfPlayers":
        case "spaceRequirement":
        case "organization":
        case "instructions": {
          const isValid = value;
          handleValidationStateChange(key, isValid);
          if (!isValid) hasErrors.push(key);
          break;
        }
        case "gradeBands":
        case "skillsAndConcepts": {
          const isValid = value.length;
          handleValidationStateChange(key, isValid);
          if (!isValid) hasErrors.push(key);
          break;
        }
        case "videoEnglish":
        case "videoSpanish": {
          if (value) {
            const isValid = videoInputRegex.test(value);
            handleValidationStateChange(key, isValid);
            if (!isValid) hasErrors.push(key);
          }
          break;
        }
        default:
      }
    });

    return hasErrors;
  };

  const handleSubmit = async () => {
    const hasErrors = validateForm();
    if (hasErrors.length > 0) {
      errorAlert("Please clear all the error before submit");
      return;
    }

    try {
      const extractedDataFromFormData = Object.entries(activityFormData).reduce(
        (acc, [key, value]) => {
          if (key === "tabData") return acc;

          if (key === "subStandardsAlignments") {
            acc[value.key] = value.value
              .split(",")
              .map((item: string) => item.trim());
            return acc;
          }

          acc[value.key] = value.value;
          return acc;
        },
        {} as {
          [key: string]:
            | boolean
            | string
            | string[]
            | BaseResponse
            | BaseResponse[];
        },
      );

      const updatedData: Partial<IActivity> = {
        ...activityData,
        ...extractedDataFromFormData,
        diagram: [
          ...activityFormData.diagram.value,
          ...(activityFormData.diagram.uploadedData.length > 0
            ? activityFormData.diagram.uploadedData.map((file) =>
                URL.createObjectURL(file),
              )
            : []),
        ],
      };

      const updatedDataAPI: {
        [key: string]: string | number | boolean | string[] | string;
      } = {};

      Object.entries(updatedData).forEach(([key, value]) => {
        if (
          [
            "name",
            "type",
            "subType",
            "numberOfPlayers",
            "spaceRequirement",
            "progressionLevel",
            "videoEnglish",
            "videoSpanish",
            "inclusionGuideCrosslink",
            "organization",
            "equipmentDescription",
            "instructions",
            "teachingSuggestions",
            "variations",
            "differentiation",
            "selReinforcement",
            "inclusionAdaptation",
            "virtualAdaption",
          ].includes(key)
        ) {
          updatedDataAPI[key] =
            value && typeof value === "object" && "_id" in value
              ? (value as BaseResponse)._id
              : (value as string | number | boolean);
        } else if (
          [
            "gradeBands",
            "standardsAlignments",
            "subStandardsAlignments",
            "selCompetencies",
            "skillsAndConcepts",
            "equipment",
            "inclusionGuideCrosslink",
          ].includes(key)
        ) {
          updatedDataAPI[key] = (value as BaseResponse[]).map((item) =>
            typeof item === "object" && "_id" in item
              ? item._id
              : (item as unknown as string),
          );
        } else if (key === "diagram") {
          updatedDataAPI[key] = (value as string[]).filter(
            (item) => !item.includes("blob"),
          );
        } else {
          updatedDataAPI[key] = value as string | string[] | number | boolean;
        }
      });

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ...updatedDataAPI,
          isFreeSample: updatedDataAPI.isFreeSample ? "true" : "false",
          hasSpanishTranslation: updatedDataAPI.hasSpanishTranslation
            ? "true"
            : "false",
          isEquipmentRequired: updatedDataAPI.isEquipmentRequired
            ? "true"
            : "false",
        }),
      );

      activityFormData.diagram.uploadedData.forEach((item) => {
        formData.append("diagram", item);
      });

      if (id) {
        const updatedActivity = await updateActivity(errorAlert, id, formData);
        if (updatedActivity) {
          setActivityData(updatedActivity);
          successAlert("Activity updated successfully");
        }
      } else {
        const createdActivity = await createActivity(errorAlert, formData);
        if (createdActivity) {
          successAlert("Activity created successfully");
          navigate(`/activityForm/${createdActivity._id}`);
        }
      }
    } catch (error) {
      errorAlert("Error updating activity");
    }
  };

  // when id is given but no data is found
  if (id && !activityData) {
    return (
      <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
        <Paper
          sx={{
            padding: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography variant="h6" sx={EditSingleStyles.typoLabel}>
            No Activity Found. Please try again.
          </Typography>
          <Link to="/">
            <Button variant="contained" className="centerAlign">
              Go Back
            </Button>
          </Link>
        </Paper>
      </Box>
    );
  }

  return (
    <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={EditSingleStyles.typoLabel}>
          {activityData ? "Edit Activity" : "Create New Activity"}
        </Typography>
      </Grid>
      <Paper sx={{ padding: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={9} className="gridContainer">
            <Grid container className="borderBottomStyle">
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.name.title}
                    isRequired={activityFormData.name.isRequired}
                  />
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  className="custom-width"
                  value={activityFormData.name.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextFieldChange("name", e);
                  }}
                  error={!activityFormData.name.isValid}
                  helperText={
                    !activityFormData.name.isValid && (
                      <FormHelperText>
                        <span className="custom-validation-error-text">
                          {activityFormData.name.validationErrorMsg}
                        </span>
                      </FormHelperText>
                    )
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={EditSingleStyles.gridAlign}
                className="checkbox"
              >
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.isFreeSample.title}
                    isRequired={activityFormData.isFreeSample.isRequired}
                  />
                </Typography>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup className="radioLabels">
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={activityFormData.isFreeSample.value ? "Yes" : "No"}
                      onChange={(event) =>
                        handleAllRadioChange("isFreeSample", event)
                      }
                      row
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.type.title}
                    isRequired={activityFormData.type.isRequired}
                  />
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={activityFormData.type.options}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    defaultValue={activityFormData.type.value || null}
                    onChange={(_, newValue) => {
                      handleValueChange("type", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!activityFormData.type.isValid}
                        {...params}
                      />
                    )}
                  />
                  {!activityFormData.type.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.type.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.subType.title}
                    isRequired={activityFormData.subType.isRequired}
                  />
                </Typography>
                <Autocomplete
                  id="tags-outlined"
                  disabled={Boolean(!activityFormData.type.value)}
                  options={activityFormData.subType.displayOptions}
                  className="custom-width"
                  value={activityFormData.subType.value}
                  getOptionLabel={(option: BaseResponse) => option.value}
                  onChange={(_, newValue) => {
                    handleValueChange("subType", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  classes={{
                    option: "autocomplete-option",
                    noOptions: "autocomplete-option",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={EditSingleStyles.gridAlign}
                className="checkbox"
              >
                <Typography variant="subtitle2" sx={EditSingleStyles.typoLabel}>
                  <FieldLabel
                    title={activityFormData.gradeBands.title}
                    isRequired={activityFormData.gradeBands.isRequired}
                  />
                </Typography>

                <FormControl
                  sx={EditSingleStyles.checkboxLabelMargin}
                  component="fieldset"
                  variant="standard"
                  size="small"
                >
                  <FormGroup row>
                    {activityFormData.gradeBands.options.map((band) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={activityFormData.gradeBands.value.some(
                              (selected) => selected._id === band._id,
                            )}
                            onChange={() =>
                              handleAllCheckboxChange("gradeBands", band)
                            }
                            name={band.value}
                          />
                        }
                        label={<span className="formLabel">{band.value}</span>}
                        key={band._id}
                      />
                    ))}
                  </FormGroup>
                  {!activityFormData.gradeBands.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.gradeBands.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className="borderBottomStyle">
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.numberOfPlayers.title}
                    isRequired={activityFormData.numberOfPlayers.isRequired}
                  />
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={activityFormData.numberOfPlayers.options}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    defaultValue={
                      activityFormData?.numberOfPlayers.value || null
                    }
                    onChange={(_, newValue) => {
                      handleValueChange("numberOfPlayers", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!activityFormData.numberOfPlayers.isValid}
                        {...params}
                      />
                    )}
                  />
                  {!activityFormData.numberOfPlayers.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.numberOfPlayers.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.spaceRequirement.title}
                    isRequired={activityFormData.spaceRequirement.isRequired}
                  />
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="type-autocomplete"
                    options={activityFormData.spaceRequirement.options}
                    className="custom-width"
                    getOptionLabel={(option) => option.value}
                    defaultValue={
                      activityFormData.spaceRequirement.value || null
                    }
                    onChange={(_, newValue) => {
                      handleValueChange("spaceRequirement", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        error={!activityFormData.spaceRequirement.isValid}
                        {...params}
                      />
                    )}
                  />
                  {!activityFormData.spaceRequirement.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.spaceRequirement.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyle">
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={EditSingleStyles.gridAlign}
                className="checkbox"
              >
                <Typography variant="subtitle2" sx={EditSingleStyles.typoLabel}>
                  <FieldLabel
                    title={activityFormData.standardsAlignments.title}
                    isRequired={activityFormData.standardsAlignments.isRequired}
                  />
                </Typography>

                <FormControl
                  sx={EditSingleStyles.radioLabels}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup row>
                    {activityFormData.standardsAlignments.options.map(
                      (standard) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={activityFormData.standardsAlignments.value.some(
                                (selected) => selected._id === standard._id,
                              )}
                              onChange={() =>
                                handleAllCheckboxChange(
                                  "standardsAlignments",
                                  standard,
                                )
                              }
                              name={standard.value}
                            />
                          }
                          label={
                            <span className="formLabel">{standard.value}</span>
                          }
                          key={standard._id}
                        />
                      ),
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <Typography variant="subtitle2" sx={EditSingleStyles.typoLabel}>
                  <FieldLabel
                    title={activityFormData.subStandardsAlignments.title}
                    isRequired={
                      activityFormData.subStandardsAlignments.isRequired
                    }
                  />
                </Typography>

                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  className="custom-width"
                  value={activityFormData.subStandardsAlignments.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextFieldChange("subStandardsAlignments", e);
                  }}
                  placeholder="Enter multiple values with separated by comma"
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="checkbox">
                <Typography
                  variant="subtitle2"
                  ml={1}
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.selCompetencies.title}
                    isRequired={activityFormData.selCompetencies.isRequired}
                  />
                </Typography>
                <FormControl
                  sx={EditSingleStyles.radioLabels}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup row>
                    {activityFormData.selCompetencies.options.map(
                      (selCompetency) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={activityFormData.selCompetencies.value.some(
                                (selected) =>
                                  selected._id === selCompetency._id,
                              )}
                              onChange={() =>
                                handleAllCheckboxChange(
                                  "selCompetencies",
                                  selCompetency,
                                )
                              }
                              name={selCompetency.value}
                            />
                          }
                          label={
                            <span className="formLabel">
                              {selCompetency.value}
                            </span>
                          }
                          key={selCompetency._id}
                        />
                      ),
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyle">
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.progressionLevel.title}
                    isRequired={activityFormData.progressionLevel.isRequired}
                  />
                </Typography>
                <Autocomplete
                  disablePortal
                  id="type-autocomplete"
                  options={activityFormData.progressionLevel.options}
                  className="custom-width"
                  getOptionLabel={(option) => option.value}
                  defaultValue={activityFormData.progressionLevel.value || null}
                  onChange={(_, newValue) => {
                    handleValueChange("progressionLevel", newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.skillsAndConcepts.title}
                    isRequired={activityFormData.skillsAndConcepts.isRequired}
                  />
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={activityFormData.skillsAndConcepts.options}
                    className="custom-width"
                    getOptionLabel={(option: BaseResponse) => option.value}
                    defaultValue={
                      activityFormData.skillsAndConcepts.value || []
                    }
                    onChange={(_, newValue) => {
                      handleValueChange("skillsAndConcepts", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!activityFormData.skillsAndConcepts.isValid}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    classes={{
                      option: "autocomplete-option",
                      noOptions: "autocomplete-option",
                    }}
                    ChipProps={{
                      className: "autocomplete-chip",
                      variant: "filled",
                      deleteIcon: (
                        <CancelRoundedIcon className="autocomplete-delete-icon" />
                      ),
                    }}
                  />
                  {!activityFormData.skillsAndConcepts.isValid && (
                    <FormHelperText error>
                      <span className="custom-validation-error-text">
                        {activityFormData.skillsAndConcepts.validationErrorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2" sx={EditSingleStyles.typoLabel}>
                  <FieldLabel
                    title={activityFormData.equipment.title}
                    isRequired={activityFormData.equipment.isRequired}
                  />
                </Typography>
                <Autocomplete
                  multiple
                  id="equipment-autocomplete"
                  options={activityFormData.equipment.options}
                  className="custom-width"
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  value={activityFormData.equipment.value || []}
                  onChange={(e, newValue) => {
                    handleValueChange("equipment", newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px ",
                      }}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          selected ||
                          (activityFormData.equipment.value?.some(
                            (e) => e._id === option._id,
                          ) ??
                            false)
                        }
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  ChipProps={{
                    className: "autocomplete-chip",
                    variant: "filled",
                    deleteIcon: (
                      <CancelRoundedIcon className="autocomplete-delete-icon" />
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className="borderBottomStyles">
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.inclusionGuideCrosslink.title}
                    isRequired={
                      activityFormData.inclusionGuideCrosslink.isRequired
                    }
                  />
                </Typography>
                <TextField
                  id="outlined-basic"
                  size="small"
                  error={!activityFormData.inclusionGuideCrosslink.isValid}
                  helperText={
                    !activityFormData.inclusionGuideCrosslink.isValid && (
                      <FormHelperText>
                        <span className="custom-validation-error-text">
                          {
                            activityFormData.inclusionGuideCrosslink
                              .validationErrorMsg
                          }
                        </span>
                      </FormHelperText>
                    )
                  }
                  variant="outlined"
                  className="custom-width"
                  value={activityFormData.inclusionGuideCrosslink.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextFieldChange("inclusionGuideCrosslink", event);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.hasSpanishTranslation.title}
                    isRequired={
                      activityFormData.hasSpanishTranslation.isRequired
                    }
                  />
                </Typography>
                <FormControl
                  sx={EditSingleStyles.radioLabels}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup className="radioLabels">
                    <RadioGroup
                      aria-labelledby="has-spanish-translation-group"
                      name="has-spanish-translation-group"
                      value={
                        activityFormData.hasSpanishTranslation.value
                          ? "True"
                          : "False"
                      }
                      onChange={(event) =>
                        handleAllRadioChange("hasSpanishTranslation", event)
                      }
                      row
                    >
                      <FormControlLabel
                        value="True"
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value="False"
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={EditSingleStyles.gridAlign}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.isEquipmentRequired.title}
                    isRequired={activityFormData.isEquipmentRequired.isRequired}
                  />
                </Typography>
                <FormControl
                  sx={EditSingleStyles.radioLabels}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup className="radioLabels">
                    <RadioGroup
                      aria-labelledby="equipment-required-group"
                      name="equipment-required-group"
                      value={
                        activityFormData.isEquipmentRequired.value
                          ? "True"
                          : "False"
                      }
                      onChange={(event) =>
                        handleAllRadioChange("isEquipmentRequired", event)
                      }
                      row
                    >
                      <FormControlLabel
                        value="True"
                        control={<Radio />}
                        label="True"
                      />
                      <FormControlLabel
                        value="False"
                        control={<Radio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="gridContainer">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.videoEnglish.title}
                    isRequired={activityFormData.videoEnglish.isRequired}
                  />
                </Typography>
                <ToggleButton
                  value="check"
                  color="primary"
                  selected={activityFormData.videoEnglish.isUploaderActive}
                  onChange={() =>
                    handleUploaderChange(
                      "videoEnglish",
                      activityFormData.videoEnglish.isUploaderActive,
                    )
                  }
                  style={{
                    margin: "0px 0px 10px 20px",
                  }}
                >
                  {activityFormData.videoEnglish.isUploaderActive ? (
                    <DeleteIcon sx={{ color: "black" }} />
                  ) : (
                    <UploadFileIcon sx={{ color: "#006fbf" }} />
                  )}
                </ToggleButton>
                {activityFormData.videoEnglish.isUploaderActive && (
                  <TextField
                    id="outlined-basic"
                    size="small"
                    error={!activityFormData.videoEnglish.isValid}
                    helperText={
                      !activityFormData.videoEnglish.isValid && (
                        <FormHelperText>
                          <span className="custom-validation-error-text">
                            {activityFormData.videoEnglish.validationErrorMsg}
                          </span>
                        </FormHelperText>
                      )
                    }
                    variant="outlined"
                    className="custom-width"
                    placeholder="Vimeo Id"
                    value={activityFormData.videoEnglish.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleTextFieldChange("videoEnglish", event)
                    }
                    sx={{ mb: 1 }}
                  />
                )}
                {activityFormData.videoEnglish.value && (
                  <div className="videoFrame">
                    <iframe
                      src={`https://player.vimeo.com/video/${activityFormData.videoEnglish.value}?badge=0&vimeo_logo=false`}
                      allow="autoplay; fullscreen; picture-in-picture"
                      title={
                        activityData
                          ? `${activityData.name} - CATCH Physical Activity Demo Video`
                          : "CATCH Physical Activity Demo Video"
                      }
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.videoSpanish.title}
                    isRequired={activityFormData.videoSpanish.isRequired}
                  />
                </Typography>
                <ToggleButton
                  value="check"
                  color="primary"
                  selected={activityFormData.videoSpanish.isUploaderActive}
                  onChange={() =>
                    handleUploaderChange(
                      "videoSpanish",
                      activityFormData.videoSpanish.isUploaderActive,
                    )
                  }
                  style={{
                    margin: "0px 0px 10px 20px",
                  }}
                >
                  {activityFormData.videoSpanish.isUploaderActive ? (
                    <DeleteIcon sx={{ color: "black" }} />
                  ) : (
                    <UploadFileIcon sx={{ color: "#006fbf" }} />
                  )}
                </ToggleButton>
                {activityFormData.videoSpanish.isUploaderActive && (
                  <TextField
                    id="outlined-basic"
                    size="small"
                    error={!activityFormData.videoSpanish.isValid}
                    helperText={
                      !activityFormData.videoSpanish.isValid && (
                        <FormHelperText>
                          <span className="custom-validation-error-text">
                            {activityFormData.videoSpanish.validationErrorMsg}
                          </span>
                        </FormHelperText>
                      )
                    }
                    variant="outlined"
                    className="custom-width"
                    placeholder="Vimeo Id"
                    value={activityFormData.videoSpanish.value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleTextFieldChange("videoSpanish", event)
                    }
                    sx={{ mb: 1 }}
                  />
                )}
                {activityFormData.videoSpanish.value && (
                  <div className="videoFrame">
                    <iframe
                      src={`https://player.vimeo.com/video/${activityFormData.videoSpanish.value}?badge=0&vimeo_logo=false`}
                      allow="autoplay; fullscreen; picture-in-picture"
                      title={
                        activityData
                          ? `${activityData.name} - CATCH Physical Activity Demo Video`
                          : "CATCH Physical Activity Demo Video "
                      }
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <FieldLabel
                    title={activityFormData.diagram.title}
                    isRequired={activityFormData.diagram.isRequired}
                  />
                </Typography>
                <SingleFileUploader onFileUpload={handleDiagramUpload} />
                {activityFormData.diagram.value.map(
                  (item: unknown, itemIndex) =>
                    typeof item === "string" && (
                      <div key={item}>
                        <img
                          src={item}
                          alt="Organization's Logo"
                          className="orgImage"
                        />
                        <ToggleButton
                          value="check"
                          color="primary"
                          selected={false}
                          onChange={() => {
                            const remainingImages =
                              activityFormData.diagram.value.filter(
                                (_, index) => index !== itemIndex,
                              );
                            handleValueChange("diagram", remainingImages);
                          }}
                          style={{ margin: "10px 0px 10px 20px" }}
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </ToggleButton>
                      </div>
                    ),
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="gridContainer">
          <TabComponent
            value={activityFormData.tabData.tabId}
            handleChange={handleTabChange}
            tabData={currentTab.value}
            handleTabDataChange={handleTabDataChange}
            isEditing
          />
          <br />
          {!activityFormData.organization.isValid && (
            <span className="custom-validation-error-text">
              {activityFormData.organization.validationErrorMsg}
            </span>
          )}
          <br />
          {!activityFormData.instructions.isValid && (
            <span className="custom-validation-error-text">
              {activityFormData.instructions.validationErrorMsg}
            </span>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            variant="contained"
            className="centerAlign"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}
