import { AxiosError } from "axios";
import errorHandling from "../utils/errorHandling";
import ILocalStorageEnum from "../enums/LocalStorageEnum";
import BASE_API from "./axios";

async function getAllMasterData(
  errorAlert: (message: string) => void,
): Promise<BaseResponse[] | null> {
  try {
    const response = await BASE_API.get<BaseResponse[]>("/master");
    return response.data;
  } catch (err) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return Promise.resolve(null);
  }
}

async function createMasterData(
  data: IMasterDataObject,
  errorAlert: (message: string) => void,
): Promise<BaseResponse | null> {
  try {
    const thinkificUserDetails = localStorage.getItem(
      ILocalStorageEnum.THINKIFIC_USER_DETAILS,
    );

    if (!thinkificUserDetails) {
      errorAlert("You need to log in first");
      return null;
    }

    const { accessToken, username } = JSON.parse(thinkificUserDetails);

    const response = await BASE_API.post<BaseResponse>("/master", data, {
      headers: {
        Authorization: accessToken,
        Username: username,
      },
    });
    return response.data;
  } catch (err) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return null;
  }
}

async function editMasterData(
  id: string,
  data: IMasterDataObject,
  errorAlert: (message: string) => void,
): Promise<BaseResponse | null> {
  try {
    const response = await BASE_API.put<BaseResponse>(`/master/${id}`, data);
    return response.data;
  } catch (err) {
    errorHandling(err as Error | AxiosError, errorAlert);
    return null;
  }
}

export { getAllMasterData, createMasterData, editMasterData };
