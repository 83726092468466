const EditSingleStyles = {
  boxContainer: {
    flexGrow: 1,
    padding: 5,
    fontSize: "12px",
  },
  typoLabel: { mb: 1, fontFamily: "Montserrat" },
  radioLabels: { ml: 2, fontFamily: "Montserrat" },
  gridAlign: { textAlign: "left" },
  checkboxLabelMargin: { m: 1 },
  labelBelowMargin: { mb: 2 },
};
export default EditSingleStyles;
