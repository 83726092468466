import React, { useState } from "react";
import ViewSingleActivity from "../pages/single-activity/view-single-activity/view-single-activity";
import SearchActivity from "../pages/search/search-activity";

const ToggleSearchViewSingle = () => {
  const [activityId, setActivityId] = useState<null | string>(null);

  if (activityId) {
    return (
      <ViewSingleActivity
        activityId={activityId}
        setActivityId={setActivityId}
      />
    );
  }

  return <SearchActivity setActivityId={setActivityId} />;
};

export default ToggleSearchViewSingle;
