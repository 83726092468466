import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./userpool";

export const authenticate = (Email: string, Password: string) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const logout = () => {
  const user = UserPool.getCurrentUser();
  user?.signOut();
  window.location.href = "/";
};
